<script setup>
import { defineEmits, ref, onMounted, watch, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  dialog: Boolean,
  loading: Boolean,
})

const { t } = useI18n()

const appointmentAnnulation = ref(true)

const emit = defineEmits(['update:break', 'update:close'])
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="500"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{ t('dashboard.subscription.break_confirmation') }}</span>
      </v-card-title>

      <v-card-text>
        <p class="text-center text-red align-center">
          <v-icon>mdi-information-outline</v-icon>
          <span>{{ t('dashboard.subscription.alert_message') }}</span>
        </p>

        <v-row class="align-center">
          <v-col cols="12" md="8">
            <span>{{ t('dashboard.subscription.break_description') }}</span>
          </v-col>

          <v-col cols="12" md="4">
            <v-radio-group inline v-model="appointmentAnnulation">
              <v-radio
                color="blue"
                :label="t('dashboard.subscription.yes')"
                :true-value="true"
              ></v-radio>
              <v-radio
                color="blue"
                :label="t('dashboard.subscription.no')"
                :true-value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          class="bg-red px-7"
          @click="emit('update:close')"
          >{{ t('dashboard.subscription.AnnulerBtn') }}</v-btn
        >
        <v-btn
          :loading="loading"
          color="white"
          class="bg-blue px-7"
          @click="emit('update:break', { appointmentAnnulation })"
          >{{ t('dashboard.subscription.confirmBtn') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
