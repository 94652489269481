<template>
  <div class="custom-time-grid-event cursor-pointer" :style="style">
    <v-icon v-if="calendarEvent.weekRepeat == 'yes'" class="icon-repeat">
      mdi-autorenew
    </v-icon>
    <div>{{ calendarEvent.title }}</div>
    <div>
      {{
        $t('dashboard.time-slot.from_X_to_Y', {
          X: dayjs(calendarEvent.start, 'YYYY-MM-DD HH:mm')
            .format('YYYY-MM-DD H:mm')
            .split(' ')[1]
            .replace(':', 'h'),
          Y: dayjs(calendarEvent.end, 'YYYY-MM-DD HH:mm')
            .format('YYYY-MM-DD H:mm')
            .split(' ')[1]
            .replace(':', 'h'),
        })
      }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TimeSlotEvent } from '@/store/timeslot/timeSlotEvent'
import { VacationEvent } from '@/store/vacation/vacation'
import { PropType, computed } from 'vue'
import dayjs from 'dayjs'
import { useVacationEventStore } from '@/store/vacation/vacationEventStore'

function chevauchementCreneaux(debut1, fin1, debut2, fin2) {
  // Convertir les strings en objets dayjs
  let start1 = dayjs(debut1, 'YYYY-MM-DD HH:mm')
  let end1 = dayjs(fin1, 'YYYY-MM-DD HH:mm')
  let start2 = dayjs(debut2, 'YYYY-MM-DD HH:mm')
  let end2 = dayjs(fin2, 'YYYY-MM-DD HH:mm')

  // Vérifier le chevauchement
  return start1.isBefore(end2) && end1.isAfter(start2)
}
const props = defineProps({
  calendarEvent: {
    type: Object as PropType<TimeSlotEvent | VacationEvent>,
    required: true,
  },
})

const vacationStore = useVacationEventStore()

const isOnVacation = computed(() => {
  if (props.calendarEvent.type !== 'TimeSlotEvent') return false
  for (const vac of vacationStore.vacations) {
    if (
      chevauchementCreneaux(
        props.calendarEvent.start,
        props.calendarEvent.end,
        vac.start,
        vac.end,
      )
    )
      return true
  }
  return false
})

const style = computed(() => {
  let borderColor = '#24dfdd'
  let background = '#4467c5'

  if (props.calendarEvent.profileColor) {
    borderColor = props.calendarEvent.profileColor
  }

  if (props.calendarEvent.appointmentReasonColor) {
    background = props.calendarEvent.appointmentReasonColor
  }

  let style: Record<string, any> = {
    borderLeft: '4px solid ' + borderColor,
    background,
  }

  if (props.calendarEvent.type === 'VacationEvent') {
    style.borderLeft = '4px solid #6750a4'
    style.background = '#eaddff'
    style.color = '#6750a4'
  }

  if (isOnVacation.value) {
    style.opacity = '0.5 !important'
  }

  return style
})
</script>

<style scoped>
.custom-time-grid-event {
  border-radius: 4px;
  font-family: inherit;
  line-height: 1.5;
  padding: 0.125rem;
  width: 100%;
  height: 100%;
  color: #fff;
}
.icon-repeat {
  float: right;
}
</style>
