<template>
  <v-card
    color="white"
    elevation="0"
    rounded="lg"
    style="overflow: initial; z-index: initial"
    class="mb-6"
    :loading="loading ? 'secondary' : false"
  >
    <v-sheet
      class="d-flex align-center bg-surface rounded-t-lg py-1 text-h6 text-primary title font-weight-bold"
      style="justify-content: space-between"
    >
      <div
        v-if="isNew && !appStore.innerLoading"
        style="width: 100px"
        class="text-start"
      >
        <v-chip color="accent" density="compact" variant="flat" class="ml-1">{{
          $t('common.new')
        }}</v-chip>
      </div>
      <div class="d-flex justify-center flex-grow-1">{{ title }}</div>
      <div v-if="isNew" style="width: 100px"></div>
    </v-sheet>

    <div
      class="form-container py-3 px-3"
      :class="{ 'hide-content': hideWhenLoading && loading }"
    >
      <v-form ref="form" @update:modelValue="emits('update:is-valid', $event)">
        <slot></slot>
      </v-form>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@/store/app'

const appStore = useAppStore()
const $t = useI18n().t

defineProps({
  title: String,
  isNew: Boolean,
  loading: Boolean,
  hideWhenLoading: Boolean,
})

const emits = defineEmits(['update:is-valid'])
const form = ref(null)

const isValid = async () => {
  const validation = await form.value?.validate()
  return validation.valid
}

defineExpose({
  isValid,
})
</script>

<style scoped lang="scss">
.form-container {
  margin: 0 auto;
  max-width: 700px;

  &.hide-content {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
.title {
  height: 45px;
}
</style>

<style lang="scss">
.v-row {
  margin-top: 0 !important;
}
</style>
