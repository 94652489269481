import { ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'

import { ApiResponse } from '@/api/api'
import EventBus from '@/utils/eventBus'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import {
  YouAbsenceInformationData,
  FormattedYourAbsence,
} from './dialogYourAbsence'
import dayjs from 'dayjs'
import { formatTime } from '@/utils/formatTime'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
const httpClient = createHttpClient()

export const useDialogYourAbsenceStore = defineStore(
  'dialogYourAbsenceStore',
  () => {
    const consultations = ref<FormattedYourAbsence[]>([
      // {
      //   id: 1,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: avatarPng,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])
    const fetchConsultations = async () => {
      LoadingBackdropService.start()
      try {
        const res: ApiResponse<YouAbsenceInformationData[]> =
          await httpClient.get('/opinion/absence')

        if (res.status != 200) throw Error(JSON.stringify(res.data))

        consultations.value = res.data.map(consultation => {
          const tmpconsultation: FormattedYourAbsence = {
            id: consultation.id,
            appointmentReason: consultation.appointmentReason,
            customerName: consultation.customerName,
            date: capitalizeFirstLetter(
              dayjs(consultation.date).format('ddd D MMM'),
            ),
            duration: formatTime('' + consultation.duration),
            price: '' + consultation.price + ' €',
            time: dayjs(consultation.date).format('HH:mm').replace(':', 'h'),
            note: consultation.comment,
            photo: consultation.customerPicture,
          }
          return tmpconsultation
        })

        if (0 < consultations.value.length) {
          EventBus.emit('open-dialog-your-absence')
        } else {
          EventBus.emit('close-dialog-your-absence')
        }
      } catch (error) {
        console.error(error)
      } finally {
        LoadingBackdropService.stop()
      }
    }

    async function startLoop() {
      // Exécute le fetch immédiatement
      await fetchConsultations()

      // Puis exécute let fetch toutes les 5 minutes
      setInterval(fetchConsultations, 5 * 60 * 1000)
    }

    return {
      fetchConsultations,
      consultations,
      startLoop,
    }
  },
)
