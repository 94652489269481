import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import { calendarApp } from '@/components/calendar/calendarApp'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import { VacationData, VacationEvent } from '@/store/vacation/vacation'
import dayjs from 'dayjs'

export const useVacationEventStore = defineStore('vacation-event', () => {
  const httpClient = createHttpClient()
  const { t } = useI18n()
  const vacations: Ref<VacationEvent[]> = ref([])

  const fillVacations = async () => {
    try {
      const response: AxiosResponse<VacationData[]> =
        await httpClient.get(`/vacation/all`)
      if (response.status !== 200) {
        throw Error(JSON.stringify(response))
      }
      vacations.value = formatResponse(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const formatResponse = (vacationsData: VacationData[]): VacationEvent[] => {
    return vacationsData.map(v => {
      const start = dayjs(v.startDate).format('YYYY-MM-DD HH:mm')
      const end = dayjs(v.endDate).format('YYYY-MM-DD HH:mm')
      return {
        id: v.id,
        title: t('dashboard.time-slot.dialogVacation.vacation'),
        start,
        end,
        type: 'VacationEvent',
      }
    })
  }

  const initVacations = async () => {
    try {
      await fillVacations()
    } catch (error) {
      MessageService.error(t('dashboard.time-slot.errorGetTimeSlot'))
    }
  }

  return {
    vacations,
    initVacations,
    formatResponse,
  }
})
