<template>
  <v-input class="my-2 align-center">
    <div
      class="d-flex w-100 border rounded-pill align-center px-4 py-5 border-opacity-50 card-slot"
    >
      <slot></slot>
    </div>
  </v-input>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.card-slot {
  min-height: 61px;
}
</style>
