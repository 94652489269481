<template>
  <div class="d-flex">
    <ScheduleXCalendar
      v-resize="onResize"
      :calendar-app="calendarApp"
      :custom-components="customComponents"
    />
  </div>
  <DialogVacation />
  <DialogTimeSlot />
  <v-dialog
    v-model="dialogYouNeedProfilesAndAddress"
    persistent
    :min-width="xs ? 300 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{
          $t('dashboard.time-slot.dialogYouNeedProfileAndAddress.title')
        }}</span>
      </v-card-title>

      <v-card-text class="d-flex flex-column ga-5 px-4 px-sm-10 pt-10">
        <span>{{
          $t('dashboard.time-slot.dialogYouNeedProfileAndAddress.message')
        }}</span>
      </v-card-text>
      <v-card-actions
        class="pr-7 pl-5 ga-4 d-flex flex-wrap justify-end align-center pb-8"
      >
        <router-link :to="{ name: 'DashboardAddress' }">
          <v-btn class="px-6" variant="outlined" color="blue-darken-1">
            {{
              $t(
                'dashboard.time-slot.dialogYouNeedProfileAndAddress.goToAddresses',
              )
            }}
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'DashboardProfile' }">
          <v-btn class="px-6" variant="flat" color="blue-darken-1">
            {{
              $t(
                'dashboard.time-slot.dialogYouNeedProfileAndAddress.goToProfiles',
              )
            }}
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/index.css'
import { ScheduleXCalendar } from '@holitime/schedule-x-vue'
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { calendarApp } from '@/components/calendar/calendarApp'
import { CustomComponents } from '@holitime/schedule-x-shared'
import CustomSidebarWrapper from '@/components/calendar/CustomSidebarWrapper.vue'
import { useDisplay } from 'vuetify'
import { useSidebarState } from '@/components/calendar/calendarState'
import DialogVacation from '@/components/calendar/DialogVacation.vue'
import { onMounted, ref } from 'vue'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import EventBus from '@/utils/eventBus'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { InitTimeSlotParams } from '@/store/timeslot/timeSlotEvent'
import CustomTimeGridEvent from '@/components/calendar/CustomTimeGridEvent.vue'
import { useUserStore } from '@/store/user/userStore'
import DialogTimeSlot from '@/components/calendar/DialogTimeSlot.vue'
import CustomMonthGridEvent from '@/components/calendar/CustomMonthGridEvent.vue'
import CustomMonthAgenda from '@/components/calendar/CustomMonthAgenda.vue'

const { initTimeSlots } = useTimeSlotStore()
const appointmentReasonStore = useAppointmentReasonStore()
const userStore = useUserStore()
EventBus.on(
  'initTimeSlots',
  (
    {
      profileIds,
      appointmentReasonIds,
      addressIds,
      ownerId,
      start,
      end,
    }: InitTimeSlotParams = {
      profileIds: userStore.profiles.map(p => p.id),
      appointmentReasonIds: appointmentReasonStore.appointmentReasons.map(
        a => a.id,
      ),
      addressIds: userStore.addresses.map(a => a.id),
      start: null,
      end: null,
    },
  ) => {
    initTimeSlots({
      profileIds,
      appointmentReasonIds,
      addressIds,
      ownerId,
      start,
      end,
    })
  },
)

const dialogYouNeedProfilesAndAddress = ref(false)
const openDialogYouNeedProfilesAndAddress = () => {
  dialogYouNeedProfilesAndAddress.value = true
}
const closeDialogYouNeedProfilesAndAddress = () => {
  dialogYouNeedProfilesAndAddress.value = false
}

onMounted(async () => {
  await userStore.initProfiles()
  await userStore.initAddresses()
  if (
    !userStore.addresses ||
    !userStore.addresses.length ||
    !userStore.profiles ||
    !userStore.profiles.length
  ) {
    openDialogYouNeedProfilesAndAddress()
  }
  initTimeSlots()
})

const customComponents: CustomComponents = {
  sidebar: CustomSidebarWrapper as unknown as any,
  // dateGridEvent: CustomDateGridEvent as unknown as any,
  timeGridEvent: CustomTimeGridEvent as unknown as any,
  monthGridEvent: CustomMonthGridEvent as unknown as any,
  monthAgendaEvent: CustomMonthAgenda as unknown as any,
}

const { lgAndUp, xs } = useDisplay()
const { sidebarState } = useSidebarState()

const onResize = () => {
  const calendarContainer = document.querySelector('.sx__calendar-content')
  if (!calendarContainer) return
  if (lgAndUp.value && sidebarState.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
  if (
    lgAndUp.value &&
    calendarContainer.classList.contains('hidden-sidebar') &&
    !sidebarState.value
  ) {
    calendarContainer.classList.add('hidden-sidebar')
  }
  if (!lgAndUp.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
}
</script>

<style lang="scss">
@import url('./styleCalendar.css');
</style>
