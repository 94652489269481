import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import createHttpClient from '@/api/httpClient'
import {
  AppointmentReasonInformationData,
  FormattedAppointmentReasonInformationData,
} from '@/api/appointment-reason/appointment-reason'
import { ApiResponse } from '@/api/api'

const httpClient = createHttpClient()

export const useAppointmentReasonStore = defineStore(
  'appointment-reason',
  () => {
    const appointmentReasons: Ref<FormattedAppointmentReasonInformationData[]> =
      ref([])
    const fillAppointmentReasons = async () => {
      try {
        const res: ApiResponse<AppointmentReasonInformationData[]> =
          await httpClient.get('/appointment-reason/me')
        if (res.status != 200) throw Error(JSON.stringify(res.data))

        // Fomat data to use
        appointmentReasons.value = res.data.map(motive => ({
          ...motive,
          remote: motive.remote ? 'yes' : 'no',
        }))
      } catch (error) {
        console.error(error)
      }
    }

    return {
      appointmentReasons,
      fillAppointmentReasons,
    }
  },
)
