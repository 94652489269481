<script setup>
import { defineEmits, ref, onMounted, watch, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import countries from '@/shared/countries.json'
const { t } = useI18n()
const emit = defineEmits(['update:company', 'update:close'])

const requiredRules = [v => !!v || `${t('dashboard.subscription.required')}`]

const props = defineProps({
  dialog: Boolean,
  infos: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const form = ref({
  nameCompany: '',
  siret: '',
  addressCompany: '',
  countryCompany: '',
  postalCodeCompany: '',
  cityCompany: '',
  tvaNumberCompany: '',
})

const isValidForm = ref(false)

const onSubmit = () => {
  if (!isValidForm.value) return false
  emit('update:company', form.value)
}

watch(
  () => props.infos,
  newVal => {
    form.value = { ...newVal }
  },
  { immediate: true },
)
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{
          t('dashboard.subscription.update_information_modal_title')
        }}</span>
        <v-btn
          @click="emit('update:close')"
          class="mr-2"
          variant="plain"
          icon="mdi-close"
          style="position: absolute; right: 0"
        ></v-btn>
      </v-card-title>

      <v-form @submit.prevent="onSubmit" v-model="isValidForm">
        <v-card-text class="px-15">
          <v-text-field
            density="compact"
            :label="t('dashboard.subscription.company_label')"
            v-model="form.nameCompany"
          ></v-text-field>

          <v-text-field
            density="compact"
            :label="t('dashboard.subscription.siret')"
            v-model="form.siret"
          ></v-text-field>

          <v-text-field
            density="compact"
            :label="t('dashboard.subscription.tva_number')"
            v-model="form.tvaNumberCompany"
          ></v-text-field>

          <v-autocomplete
            density="compact"
            class="mb-5"
            v-model="form.countryCompany"
            :items="countries"
            :label="$t('dashboard.addresses.selectCountry')"
            :rules="requiredRules"
            clearable
            hide-details
          />

          <v-text-field
            density="compact"
            :label="`${t('dashboard.subscription.adresse')}*`"
            v-model="form.addressCompany"
            :rules="requiredRules"
            required
          ></v-text-field>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                density="compact"
                :label="`${t('dashboard.subscription.postal_code')}*`"
                v-model="form.postalCodeCompany"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                density="compact"
                :label="`${t('dashboard.subscription.city')}*`"
                v-model="form.cityCompany"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            type="submit"
            :disabled="!isValidForm"
            color="white"
            class="bg-blue px-5"
            >{{ t('dashboard.subscription.saveBtn') }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
