<script setup lang="ts">
import { defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

const props = defineProps({
  message: String,
  price: {
    type: Number,
    default: 50,
  },
  actualPrice: {
    type: Number,
    default: 37.5,
  },
  isSubscribed: {
    type: Boolean,
    default: true,
  },
  discount: {
    type: Number,
    default: 12.5,
  },
  nextBillDate: {
    type: String,
    default: '',
  },
  tva: {
    type: Number,
    default: 0,
  },
  preSubActive: {
    type: Boolean,
    default: false,
  },
  blocMessage: {
    type: String,
    default: '',
  },
  displayOldPrice: {
    type: Boolean,
    default: true,
  },
  displayAmount: {
    type: Boolean,
    default: false,
  },
})
const { t } = useI18n()
</script>

<template>
  <div class="">
    <v-card
      color="white"
      border-color="blue"
      border="md"
      max-width="600"
      class="pa-3 elevation-0"
    >
      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <div
            class="text-h6 d-flex justify-center align-center"
            v-if="isSubscribed"
          >
            <template v-if="displayOldPrice && discount !== null">
              <span class="old_price text-green-100 text-h6 pa-2"
                >{{ price }}€</span
              >
            </template>
            <div class="d-flex align-center">
              <span class="text-secondary text-h4 mx-2">
                <strong>{{ actualPrice }}</strong>
              </span>
              <span class="text-body-2"
                >€ {{ tva > 0 ? 'TTC' : 'H.T' }} /mois</span
              >
            </div>
          </div>
          <div class="ma-2" style="word-wrap: break-word; white-space: normal">
            <div
              v-if="isSubscribed"
              class="bg-secondary px-2 rounded-lg text-subtitle-2 text-center"
            >
              <span>{{ blocMessage ? blocMessage : t('offer_valid') }}</span>
            </div>

            <v-row class="">
              <v-col
                cols="12"
                class="py-0"
                :class="preSubActive ? 'order-2' : 'order-1'"
              >
                <div
                  class="my-1 text-caption font-weight-bold"
                  v-if="nextBillDate"
                >
                  {{ t('nextPaiementDate') }}:
                  {{ dayjs(nextBillDate).format('D MMMM YYYY') }}
                </div>
                <div
                  class="d-flex align-center text-caption"
                  v-if="displayAmount"
                >
                  <span class="text-caption mr-1">Montant:</span>
                  <span class="">
                    {{ actualPrice }}
                  </span>
                  <span class="">€ {{ tva > 0 ? 'TTC' : 'H.T' }}</span>
                </div>
              </v-col>

              <v-col class="py-0" :class="preSubActive ? 'order-1' : 'order-2'">
                <div
                  class="italic mt-1 text-secondary"
                  :style="{
                    fontSize: isSubscribed ? '14px' : '21px',
                    fontStyle: 'italic',
                  }"
                >
                  <v-icon v-if="message" icon="mdi-check-circle"></v-icon>
                  {{ message ?? '' }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" sm="6" class="text-center">
          <div>
            <span>{{ t('appointment_booking') }}</span
            ><br />
            <v-icon color="secondary">mdi-plus</v-icon>
          </div>
          <div>
            <span>{{ t('up_profiles') }}</span
            ><br />
            <v-icon color="secondary">mdi-plus</v-icon>
          </div>
          <div>{{ t('up_adresses') }}</div>
          <div>
            <v-icon color="secondary">mdi-dots-horizontal</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
