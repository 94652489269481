import { AxiosResponse } from 'axios'
import { ApiResponse } from '@/api/api.d'
import { SearchResponse } from './typesense.d'
import { typesenseClient } from '../typesenseClient'

export const findPractitioner = async (
  searchText: string,
  latitude: string,
  longitude: string,
  radius: string,
  filter_by: string = '',
  page = 1,
  perPage = 15,
): Promise<ApiResponse<SearchResponse>> => {
  const response: AxiosResponse<SearchResponse> =
    await typesenseClient.get<SearchResponse>(
      `/collections/profiles/documents/search?q=${searchText}&query_by=practitionerName,services,disciplines,mainDiscipline&filter_by=addresses.location:(${latitude},${longitude},${radius} km)${filter_by ? ' && ' + filter_by : ''}&sort_by=addresses.location(${latitude},${longitude}):asc&facet_by=services&page=${page}&per_page=${perPage}`,
    )
  return {
    status: response.status,
    data: response.data,
  }
}
