<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card class="bg-white">
      <v-card-title
        class="pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <div class="text-center">
          {{ t('dashboard.appointment.dialogDetails.title') }}
        </div>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close"
          @click="dialog = false"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="d-flex overflow-scroll flex-column ga-2">
        <v-row class="pa-4">
          <v-col md="8" sm="12" class="left-part d-flex flex-column">
            <v-list dense>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-row>
                  <v-col cols="1">
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-content class="gray">
                      <v-list-item-title>
                        <span v-if="item.key_name == 'name'">
                          <strong>{{ item.name }}</strong>
                          <!-- TODO update the link once page done -->
                          <!-- <a href="#" class="underline-link pl-5">{{
                            t('dashboard.appointment.dialogDetails.seePatientFile')
                          }}</a> -->
                        </span>
                        <!-- <span v-else-if="item.key_name == 'date_time'">
                          Jeudi <strong>{{ item.date }}</strong> de
                          <strong>{{ item.hour_from }}</strong> à
                          <strong>{{ item.hour_from }}</strong>
                        </span> -->
                        <span v-else> {{ item.name }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
              <div class="text-caption mt-3">
                {{ t('dashboard.appointment.dialogDetails.description') }}
              </div>
              <div v-html="description"></div>
            </v-list>
            <v-spacer></v-spacer>
            <v-card-actions
              v-if="!isInPast"
              class="align-center justify-center"
            >
              <v-btn
                color="teal"
                class="px-8"
                variant="flat"
                @click="openModifyConfirm"
                >{{
                  t('dashboard.appointment.dialogDetails.modifyTheAppointment')
                }}</v-btn
              >
              <v-btn
                color="red"
                class="px-8"
                variant="flat"
                @click="openCancelConfirm"
                >{{
                  t('dashboard.appointment.dialogDetails.cancelTheAppointment')
                }}</v-btn
              >
            </v-card-actions>
            <v-card-actions v-if="isInPast" class="align-center justify-center">
              <v-btn
                color="teal"
                class="px-8"
                variant="flat"
                @click="() => confirmPatientPresence()"
              >
                {{ t('dashboard.appointment.dialogDetails.btnPresent') }}
              </v-btn>
              <v-btn
                color="red"
                class="px-8"
                variant="flat"
                @click="() => confirmPatientAbsence()"
              >
                {{ t('dashboard.appointment.dialogDetails.btnAbsent') }}
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col md="4" sm="12" class="right-part text-center">
            <span class="text-subtitle-1 mb-2"
              ><strong>
                {{
                  t('dashboard.appointment.dialogDetails.consultationPartText')
                }}</strong
              ></span
            >
            <v-list dense class="text-center mb-2 list">
              <v-btn
                v-if="loadingHistory"
                variant="text"
                :loading="loadingHistory"
              ></v-btn>
              <v-list-item-group v-if="!loadingHistory" v-model="selectedDate">
                <v-list-item v-for="(date, index) in history" :key="index">
                  <v-list-item-title
                    :class="{ highlighted: index === 0 || index === 1 }"
                    class="center-list"
                  >
                    <div class="date">
                      <div class="d-flex flex-column justify-start">
                        <span class="date-day">{{ date.day }}</span>
                        <span class="date-month"> {{ date.month }}</span>
                      </div>
                      <div class="date-year">{{ date.year }}</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- TODO update the link once page done -->
            <!-- <a href="#" class="underline-link pt-5">{{
              t('dashboard.appointment.dialogDetails.viewFullHistory')
            }}</a> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import MessageService from '../feedback/message/messageService'
import EventBus from '@/utils/eventBus'
import {
  PatientAugmentedData,
  PractitionerAppointmentEvent,
  PatientDataForCalendar,
  PractitionerAppointmentInformationData,
} from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { AxiosResponse } from 'axios'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
import { useUserStore } from '@/store/user/userStore'

dayjs.extend(utc)
const httpClient = createHttpClient()
const { t } = useI18n()

const userStore = useUserStore()

const dialog = ref(false)
const selectedDate = ref(null)
const description = ref('')
const cancelationId = ref('')

const patient: Ref<PatientAugmentedData | PatientDataForCalendar> = ref()
const valueCalendar: Ref<PractitionerAppointmentEvent> = ref()
const loadingHistory = ref(false)
const getPatientHistory = async () => {
  loadingHistory.value = true
  const patientId = patient.value.id
  const practitionnerId = userStore.user.id
  try {
    const appointmentsResponse: AxiosResponse<
      PractitionerAppointmentInformationData[]
    > = await httpClient.get(
      `/appointment/${practitionnerId}/patient/${patientId}`,
    )
    if (appointmentsResponse.status !== 200) {
      throw Error(JSON.stringify(appointmentsResponse))
    }

    const tmpArray = appointmentsResponse.data.map(appointment => ({
      day: dayjs(appointment.startDateTime).date(),
      month: dayjs(appointment.startDateTime).format('MMMM'),
      year: parseInt(dayjs(appointment.startDateTime).format('YYYY')),
      date: appointment.startDateTime,
    }))

    history.value = tmpArray.sort((a, b) => {
      const dayjsA = dayjs(a.date)
      const dayjsB = dayjs(b.date)
      if (dayjsB.isAfter(dayjsA)) {
        return 1
      }
      if (dayjsB.isBefore(dayjsA)) {
        return -1
      }
      return 0
    })
  } catch (error) {
    console.error(error)
  }
  loadingHistory.value = false
}

const confirmPatientPresence = async () => {
  LoadingBackdropService.start()
  try {
    await httpClient.post(
      `/appointment-notification/${valueCalendar.value.id}/confirmed`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
    console.error(error)
  }
  LoadingBackdropService.stop()
}

const confirmPatientAbsence = async () => {
  LoadingBackdropService.start()
  try {
    await httpClient.post(
      `/appointment-notification/${valueCalendar.value.id}/confirmed`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
    console.error(error)
  }
  LoadingBackdropService.stop()
}

const dateAppointment = ref<dayjs.Dayjs>(null)
const isInPast = computed(() => {
  return dateAppointment.value.isBefore(dayjs())
})
EventBus.on(
  'openDetailsAppointmentWithEvent',
  async (calendarEvent: PractitionerAppointmentEvent) => {
    // console.log("calendarEvent25",calendarEvent)
    valueCalendar.value = calendarEvent
    patient.value = calendarEvent.patient as PatientDataForCalendar
    dialog.value = true
    items.value[0].name = patient.value
      ? patient.value.fullName
      : 'patient undefined'
    dateAppointment.value = dayjs(calendarEvent.start, 'YYYY-MM-DD HH:mm')

    items.value[1].name = t(
      'dashboard.appointment.dialogDetails.dateFromStartToEnd',
      {
        date: capitalizeFirstLetter(
          dayjs(calendarEvent.start, 'YYYY-MM-DD HH:mm').format('dddd D MMMM'),
        ),
        start: (
          parseInt(calendarEvent.start.split(' ')[1].split(':')[0]) +
          ':' +
          calendarEvent.start.split(' ')[1].split(':')[1]
        ).replace(':', 'h'),
        end: (
          parseInt(calendarEvent.end.split(' ')[1].split(':')[0]) +
          ':' +
          calendarEvent.end.split(' ')[1].split(':')[1]
        ).replace(':', 'h'),
      },
    )
    try {
      items.value[2].name = calendarEvent.address.formattedAddress
      items.value[3].name = calendarEvent.appointmentReason
        ? calendarEvent.appointmentReason.label
        : ''
      description.value = calendarEvent.description
      cancelationId.value = calendarEvent.id
      await getPatientHistory()
    } catch (e) {
      console.error(e)
    }
  },
)
EventBus.on('close-dialog-details', async () => {
  dialog.value = false
})
// I keep comments to see an example of the data we have to put
const items = ref([
  { name: 'Ex: Thomas Pascal', icon: 'mdi-account-multiple', key_name: 'name' },
  {
    name: 'Ex: Jeudi 11 avril de 7h00 à 9h00',
    date: '11 avril',
    hour_from: '9h00',
    hour_to: '9h00',
    key_name: 'date_time',
    icon: 'mdi-clock',
  },
  { name: 'Ex: 19 Rue Charles de Gaulle. 75006 Paris', icon: 'mdi-map-marker' },
  {
    name: 'Ex: Motif de consultation 1. Motif de consultation 2',
    icon: 'mdi-information',
  },
])

const history = ref([
  // { day: 22, month: 'April', year: 2024 },
  // { day: 21, month: 'April', year: 2024 },
  // { day: 20, month: 'April', year: 2024 },
  // { day: 19, month: 'April', year: 2024 },
  // { day: 18, month: 'April', year: 2024 },
])

const openModifyConfirm = () => {
  const { toggleDialogModify } = useDialogPractitionerAppointmentStore()
  toggleDialogModify(valueCalendar)
}

const openCancelConfirm = () => {
  ConfirmDialogService.confirm({
    title: t('dashboard.appointment.dialogDetails.confirmCancelTitle'),
    message: t('dashboard.appointment.dialogDetails.confirmCancelMessage'),
    optionsConfirm: {
      onConfirm: () => {
        handleCancelAppointment()
      },
      onCancel: () => {},
    },
  })
}

const handleCancelAppointment = async () => {
  LoadingBackdropService.start()
  try {
    const deleteResponse = await httpClient.delete(
      '/appointment/' + cancelationId.value,
    )
    if (deleteResponse.status !== 200) {
      throw Error(JSON.stringify(deleteResponse))
    }
    MessageService.success(
      t('dashboard.appointment.dialogDetails.successCancelAppointment'),
    )
    const practitionerAppointmentStore = usePractitionerAppointmentStore()
    await practitionerAppointmentStore.initAppointments()
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
  LoadingBackdropService.stop()
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 10px;
}
.highlighted {
  font-size: 20px;
  font-weight: bold;
  opacity: 1;
}
.v-list.v-theme--holitimeLightTheme.v-list--density-default.v-list--one-line {
  overflow: hidden;
  background: #fff;
}
.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #efe8e8;
  padding: 5px 40px;
  box-shadow: 0px 0px 2px rgb(177 166 166 / 50%);
  height: auto;
  border-radius: 999px;
  background: #f8f8f8;
  color: #1973e9;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
  width: 200px;
  justify-content: center;
}
.highlighted .date {
  opacity: 1;
}

.v-list-item__content {
  display: flex;
  align-items: center;
}

.left-part {
  border-right: 1px solid #e0e0e0;
}

.date-day {
  display: block;
  font-size: 35px;
  font-weight: 900;
  margin-top: 5px;
  line-height: 0.9;
}
.date-month {
  font-size: 14px;
}
.date-year {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: darkgrey;
}
.center-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-list-tile-content {
  color: gray;
}
.underline-link {
  text-decoration: underline;
  color: #337dea;
  font-weight: 500;
}
.v-dialog > .v-overlay__content > .v-card {
  overflow-y: hidden;
}
.list {
  overflow-y: auto !important;
  height: 300px;
}
.overflow-scroll {
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .v-dialog > .v-overlay__content > .v-card {
    overflow-y: auto;
  }
}
</style>
