<script setup lang="ts">
import { defineEmits, ref, onMounted, watch, defineProps } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useUserStore } from '@/store/user/userStore'
import createHttpClient from '@/api/httpClient'
import { useI18n } from 'vue-i18n'
import MessageService from '@/components/feedback/message/messageService'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'

import { useStripe } from '@/shared/payment'

const props = defineProps({
  dialog: Boolean,
  cardInfo: {
    type: Object,
    required: true,
  },
})
const { t } = useI18n()
const emit = defineEmits(['update:close'])
const {
  stripe,
  stripeError,
  stripeCard,
  stripeCardExpiry,
  stripeCardCvc,
  initStripe,
} = useStripe(useTheme())

const loading = ref(false)
const firstname = ref('')
const lastname = ref('')
const userStore = useUserStore()
const globalErrors = ref()

const fieldFocus = field => {
  setTimeout(() => {
    field.focus()
  }, 10)
}

const dashboardState = useDashboardStatus()

watch(
  async () => props.dialog,
  newVal => {
    if (newVal) {
      initStripe()
    }
  },
  { immediate: true },
)

const onSubmit = async event => {
  if (stripeError.value.length) {
    return false
  }

  loading.value = true
  await stripe.value
    .createPaymentMethod({
      type: 'card',
      card: stripeCard.value,
      billing_details: {
        email: userStore.user.email,
        name: firstname.value + ' ' + lastname.value,
      },
    })
    .then(result => {
      if (result.error) {
        globalErrors.value = result.error.message
        loading.value = false
      } else {
        updateCard(result.paymentMethod.id)
      }
    })
    .catch(err => {
      loading.value = false
    })
}

const updateCard = async (pm_id: string) => {
  const req = createHttpClient()
  try {
    await req.put('/payment/create-payment-method', {
      paymentMethodId: pm_id,
    })
    emit('update:close')
    MessageService.success(t('dashboard.subscription.update_card_success'))

    // update dashboard status
    dashboardState.fetchDashboardStatus()
  } catch (err) {
    globalErrors.value = t('dashboard.subscription.update_card_error')
  } finally {
    loading.value = false
  }
}

const deleteCB = async () => {
  const res = await stripe.value
}
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{ t('dashboard.subscription.updateCard') }}</span>
        <v-btn
          @click="emit('update:close')"
          class="mr-2"
          variant="plain"
          icon="mdi-close"
          style="position: absolute; right: 0"
        ></v-btn>
      </v-card-title>

      <v-card-text class="px-md-15">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastname"
              class=""
              :label="`${t('dashboard.subscription.lastname')}*`"
              required
              type="text"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstname"
              class=""
              :label="`${t('dashboard.subscription.firstname')}*`"
              required
              type="text"
            />
          </v-col>
        </v-row>
        <card-input
          @update:focused="fieldFocus(stripeCard)"
          :error-messages="stripeError"
        >
          <div
            id="card-element"
            class="align-center border pa-3 rounded-pill px-4 py-5 border-opacity-50"
          >
            <!-- Elements will create input elements here -->
          </div>
        </card-input>

        <v-row>
          <v-col cols="6" class="mt-5">
            <card-input
              placeholder="MM / AA"
              @update:focused="fieldFocus(stripeCardExpiry)"
              hide-details
            >
              <div
                id="card-expiry"
                style="width: 100%; border-radius: 20px"
                class="align-center border pa-3 rounded-pill px-4 py-5 border-opacity-50"
              >
                <!-- Elements will create input elements here -->
              </div>
            </card-input>
          </v-col>
          <v-col cols="6" class="mt-5">
            <card-input
              placeholder="CVC"
              @update:focused="fieldFocus(stripeCardCvc)"
              hide-details
            >
              <div
                id="card-cvc"
                style="width: 100%; border-radius: 20px"
                class="align-center border pa-3 rounded-pill px-4 py-5 border-opacity-50"
              >
                <!-- Elements will create input elements here -->
              </div>
            </card-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <p class="text-red text-caption">{{ globalErrors }}</p>
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <!-- <v-row>
          <v-col cols="6">**** **** **** {{ cardInfo.lastFour }}</v-col>
          <v-col cols="6">
            <span
              >{{ t('dashboard.subscription.expired_at') }}
              {{ cardInfo.expiryMonth }}/{{ cardInfo.expiryYear }}</span
            >
            <v-menu transition="scale-transition">
              <template v-slot:activator="{ props }">
                <v-btn
                  variant="text"
                  icon="mdi-dots-horizontal"
                  v-bind="props"
                  density="compact"
                >
                </v-btn>
              </template>
              <v-list class="pa-0" style="background: white">
                <v-list-item value="1" class="pa-0 px-2">
                  <v-list-item-title @click="deleteCB" class="text-red">{{
                    t('dashboard.subscription.deleteCard')
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          @click="onSubmit"
          :loading="loading"
          color="white"
          class="bg-blue px-5"
          >{{ t('dashboard.subscription.saveBtn') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
