import axios, { AxiosResponse } from 'axios'
import { ApiResponse } from '../api'
import createHttpClient from '@/api/httpClient'
import { GeocodeEarthParams, GeocodeEarthResponse } from './geocodeearth'
import { GeoIPResponse } from './geoip'

const httpClient = createHttpClient('/utils')

export const getGeocodeEarthFeatures = async (
  params: GeocodeEarthParams,
): Promise<ApiResponse<GeocodeEarthResponse>> => {
  const response: AxiosResponse<GeocodeEarthResponse> =
    await httpClient.get<GeocodeEarthResponse>('/geo?lang=fr-FR', { params })
  return {
    status: response.status,
    data: response.data,
  }
}

export const getGeoIP = async (): Promise<ApiResponse<GeoIPResponse>> => {
  const response: AxiosResponse<GeoIPResponse> =
    await httpClient.get<GeoIPResponse>('/geoip')
  return {
    status: response.status,
    data: response.data,
  }
}

export const getReverseGeocoding = async (
  latitude: number | string,
  longitude: number | string,
): Promise<ApiResponse<GeocodeEarthResponse>> => {
  const instance = axios.create({
    baseURL: 'https://api.geocode.earth/v1/reverse?',
    timeout: 10000,
  })

  const response: AxiosResponse<GeocodeEarthResponse> =
    await instance.get<GeocodeEarthResponse>(
      `https://api.geocode.earth/v1/reverse?api_key=${import.meta.env.VITE_GEOCODE_EARTH_API_KEY}&point.lat=${latitude}&point.lon=${longitude}`,
    )
  return {
    status: response.status,
    data: response.data,
  }
}
