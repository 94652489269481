import { Ref, computed, ref } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { AddressInformationData } from '@/api/account/address'
import { useStorage } from '@vueuse/core'
import { useUserStore } from '../user/userStore'
import { TimeSlotEvent } from './timeSlotEvent.d'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason.d'
import { EnumDays } from '@/shared/enumDays'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'

const DEFAULT_TIMESLOT_DURATION = 4
const DEFAULT_TIMESLOT_UNIT = 'h'

export const useDialogTimeSlotStore = defineStore('dialog-timeslot', () => {
  const isEditing: Ref<boolean> = ref(false)
  const timeSlotId: Ref<string> = ref(null)
  const isDialogTimeSlotOpen: Ref<boolean> = ref(false)
  const selectedProfile: Ref<ProfileInformationData> = ref(null)
  const selectedAddress: Ref<AddressInformationData> = ref(null)

  const dialogTimeSlotCreationProfileAddress = useStorage(
    'dialogTimeSlotCreationProfileAddress',
    {} as
      | { address: AddressInformationData; profile: ProfileInformationData }
      | undefined
      | null,
  )
  const saveToLocalStorage = (
    profile: ProfileInformationData,
    address: AddressInformationData,
  ) => {
    dialogTimeSlotCreationProfileAddress.value = {
      profile,
      address,
    }
  }

  const selectedDate: Ref<string> = ref(dayjs().format('YYYY-MM-DD'))
  const selectedStartTime: Ref<string | 'HH:mm'> = ref(
    dayjs().format('YYYY-MM-DD HH:mm').split(' ')[1],
  )
  const selectedEndTime: Ref<string | 'HH:mm'> = ref(
    dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('YYYY-MM-DD HH:mm')
      .split(' ')[1],
  )
  const selectedAppointmentReasons = ref<
    null | FormattedAppointmentReasonInformationData[]
  >(null)
  const acceptRemote: Ref<'remote' | 'in-person' | 'both'> = ref('in-person')
  const selectedDays = ref<EnumDays[]>([])
  const repeatTimeSlot: Ref<'yes' | 'no'> = ref('no')
  const hasOnlyPresentialReasons = computed<boolean>(() => {
    return selectedAppointmentReasons.value.every(
      reason => 'no' === reason.remote,
    )
  })

  const hasErrorDate = computed(() => {
    const start = dayjs(
      `${selectedDate.value} ${selectedStartTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    const end = dayjs(
      `${selectedDate.value} ${selectedEndTime.value}`,
      'YYYY-MM-DD HH:mm',
    )

    return end.isBefore(start) || end.isSame(start)
  })

  const formValid = computed(() => {
    const start = dayjs(
      `${selectedDate.value} ${selectedStartTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    const end = dayjs(
      `${selectedDate.value} ${selectedEndTime.value}`,
      'YYYY-MM-DD HH:mm',
    )

    const hasError = end.isBefore(start) || end.isSame(start)
    if (hasError) return false
    if (
      selectedAddress.value &&
      selectedProfile.value &&
      selectedAppointmentReasons.value &&
      0 < selectedAppointmentReasons.value.length &&
      selectedDate.value &&
      acceptRemote.value
    )
      return true
    return false
  })

  const isRemote = computed(
    () => acceptRemote.value == 'remote' || acceptRemote.value == 'both',
  )
  const isPresential = computed(
    () => acceptRemote.value == 'in-person' || acceptRemote.value == 'both',
  )

  const onOpen = async () => {
    const appointmentReasonStore = useAppointmentReasonStore()
    await appointmentReasonStore.fillAppointmentReasons()
    if (!isEditing.value) {
      selectedAppointmentReasons.value = []
      const userStore = useUserStore()
      // Pre-select the first address and the first profile
      if (
        !dialogTimeSlotCreationProfileAddress.value.address &&
        !dialogTimeSlotCreationProfileAddress.value.profile
      ) {
        if (userStore.addresses.length > 0) {
          dialogTimeSlotCreationProfileAddress.value.address =
            userStore.addresses[0]
        }
        if (userStore.profiles.length > 0) {
          dialogTimeSlotCreationProfileAddress.value.profile =
            userStore.profiles[0]
        }
      }
      selectedAddress.value = dialogTimeSlotCreationProfileAddress.value.address
      selectedProfile.value = dialogTimeSlotCreationProfileAddress.value.profile
    }
  }

  /** start, end eg: 2024-12-12 09:00 */
  const openDialogWithDateTime = (start: string, end: string) => {
    selectedDate.value = start.split(' ')[0]
    selectedStartTime.value = start.split(' ')[1]
    selectedEndTime.value = end.split(' ')[1]
    isDialogTimeSlotOpen.value = true
    onOpen()
  }

  const toggleDialogTimeSlot = () => {
    const today = dayjs().format('YYYY-MM-DD HH:mm')
    selectedDate.value = today.split(' ')[0]
    selectedStartTime.value = today.split(' ')[1]
    selectedEndTime.value = dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('HH:mm')
    if (isDialogTimeSlotOpen.value) {
      resetData()
    } else {
      onOpen()
    }
    isDialogTimeSlotOpen.value = !isDialogTimeSlotOpen.value
  }

  const resetData = () => {
    timeSlotId.value = null
    isEditing.value = false
    selectedProfile.value = null
    selectedAddress.value = null
    selectedDate.value = dayjs().format('YYYY-MM-DD HH:mm')
    selectedStartTime.value = dayjs().format('HH:mm')
    selectedEndTime.value = dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('HH:mm')
    selectedAppointmentReasons.value = null
    acceptRemote.value = 'in-person'
    selectedDays.value = []
    repeatTimeSlot.value = 'no'
  }

  const openDialogWithEvent = (calendarEvent: TimeSlotEvent) => {
    const userStore = useUserStore()
    const profile = userStore.profiles.find(
      p => p.id === calendarEvent.profileId,
    )
    const address = userStore.addresses.find(
      a => a.id === calendarEvent.addressId,
    )
    timeSlotId.value = calendarEvent.timeSlotId
    selectedProfile.value = profile
    selectedAddress.value = address
    isEditing.value = true
    selectedDate.value = calendarEvent.start.split(' ')[0]
    selectedStartTime.value = calendarEvent.start.split(' ')[1]
    selectedEndTime.value = calendarEvent.end.split(' ')[1]
    selectedAppointmentReasons.value = calendarEvent.appointmentReasons
    selectedDays.value = calendarEvent.days

    acceptRemote.value = calendarEvent.acceptRemote
    repeatTimeSlot.value = calendarEvent.weekRepeat
    openDialogWithDateTime(calendarEvent.start, calendarEvent.end)
  }

  const everyXWeek = ref<number>(1)

  return {
    timeSlotId,
    dialogTimeSlotCreationProfileAddress,
    isDialogTimeSlotOpen,
    saveToLocalStorage,
    hasErrorDate,
    isEditing,
    selectedProfile,
    selectedAddress,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedAppointmentReasons,
    hasOnlyPresentialReasons,
    acceptRemote,
    selectedDays,
    onOpen,
    formValid,
    repeatTimeSlot,
    isRemote,
    isPresential,
    openDialogWithDateTime,
    openDialogWithEvent,
    resetData,
    toggleDialogTimeSlot,
    everyXWeek,
  }
})
