<template>
  <FormBlock :title="$t('dashboard.subscription.title')">
    <span
      v-if="subscriptionload"
      class="text-center font-italic d-flex justify-center text-secondary"
      >{{ $t('dashboard.subscription.loadsubscription') }}...</span
    >

    <div v-else>
      <!-- <div v-if="!cardInfo.lastFour" class="text-center">
        {{ $t('dashboard.subscription.add_payment_card') }}
      </div> -->
      <!-- <v-row class="align-center">
        <v-col cols="12" sm="8">
          <p
            class="text-subtitle-2"
            v-if="currentSubscriptionStatus?.status === 'active'"
          >
            {{ $t('dashboard.subscription.description') }}
          </p>
          <p v-else>
            <span v-if="!cardInfo.lastFour">
              {{ $t('dashboard.subscription.add_payment_card') }}
            </span>
          </p>
        </v-col>
        <v-col class="d-flex justify-center justify-sm-end" cols="12" sm="4">
          <v-btn
            v-if="currentSubscriptionStatus.status === 'active'"
            @click="toggleBreakDialog = true"
            color="blue"
            >{{ $t('dashboard.subscription.breakBtn') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!cardInfo.lastFour"
            :loading="activateLoading"
            @click="activeSubscription"
            color="blue"
            >{{ $t('dashboard.subscription.activate') }}
          </v-btn>
        </v-col>
      </v-row> -->
      <v-card
        class="px-md-10 px-2 mt-5 py-5 elevation-0 border"
        color="#FBFBFD"
      >
        <v-row>
          <v-col cols="12" class="d-md-flex justify-space-between align-center">
            <div class="d-md-flex align-center">
              <p class="mr-1 text-primary text-h6">
                {{ $t('dashboard.subscription.praticien') }}
              </p>
              <v-chip
                v-if="
                  subscriptionStore.subscriptionStatus['status'] === 'active'
                "
                variant="elevated"
                color="#E6F2EE"
                pill
                tile
                density="compact"
                rounded="pill"
                class="mr-1 py-1 px-2 elevation-0"
              >
                <div class="d-flex align-center" style="color: #32a983">
                  <v-icon>mdi-check-circle-outline</v-icon>
                  <span class="ml-1">{{
                    $t('dashboard.subscription.actif')
                  }}</span>
                </div>
              </v-chip>
              <v-chip
                v-if="
                  subscriptionStore.subscriptionStatus['status'] === 'paused'
                "
                variant="elevated"
                color="#F1E3E2"
                pill
                tile
                rounded="pill"
                class="pa-1 elevation-0"
              >
                <div class="d-flex align-center" style="color: #c38f43">
                  <v-icon>mdi-pause-circle-outline</v-icon>
                  <span class="ml-1">{{
                    $t('dashboard.subscription.in_break')
                  }}</span>
                </div>
              </v-chip>
            </div>

            <!-- <div
              class="d-md-flex align-center"
              v-if="!subscriptionStore.subscriptionStatus['isLifetime']"
            >
              <v-chip
                v-if="subscriptionStore.subscriptionStatus['status'] === 'active'"
                pill
                tile
                density="compact"
                rounded="pill"
                class="mr-1 pa-1 elevation-0 cursor-pointer"
                @click="cancelPlan = true"
              >
                <div class="d-flex align-center" style="color: #35353d">
                  <v-icon class="">mdi-cancel</v-icon>
                  <span class="ml-1">{{
                    $t('dashboard.subscription.cancel_plan')
                  }}</span>
                </div>
              </v-chip>
              <v-chip
                v-if="currentSubscriptionStatus?.type == null"
                pill
                tile
                rounded="pill"
                density="compact"
                class="pa-1 elevation-0 cursor-pointer"
                :to="{ name: 'Subscription' }"
              >
                <div class="d-flex align-items-center" style="color: #35353d">
                  <v-icon>mdi-format-rotate-90</v-icon>
                  <span v-if="available.isSubscribed" class="ml-1">{{
                    $t('dashboard.subscription.update_plan')
                  }}</span>
                  <span v-else class="ml-1">{{
                    $t('dashboard.subscription.choose_plan')
                  }}</span>
                </div>
              </v-chip>
            </div> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <recap-plan
              :tva="countryTva"
              :message="subscriptionStore.subscriptionStatus['message']"
              :price="_price"
              :actualPrice="_actualPrice"
              :isSubscribed="isSubscribed"
              :nextBillDate="
                subscriptionStore.subscriptionStatus['nextBillDate']
              "
              :preSubActive="preSubActive"
              :displayAmount="displayAmount"
            />
          </v-col>
        </v-row>
        <!--        <p class="text-gray mt-1" style="font-size: 11px">
          {{ $t('dashboard.subscription.remark') }}
        </p>-->
      </v-card>
    </div>

    <v-card color="white" class="mt-5 elevation-0 border">
      <div
        class="bg-gray-100 py-1 px-5 d-md-flex justify-space-between form-header text-primary"
      >
        <div class="align-center">
          <v-icon>mdi-information-outline</v-icon>
          <span class="ml-1">{{
            $t('dashboard.subscription.your_information')
          }}</span>
        </div>
        <div
          class="align-center cursor-pointer"
          @click="companyInfoDialog = true"
        >
          <v-icon size="15px" color="blue">mdi-pencil-box-outline</v-icon>
          <span class="ml-1">{{
            $t('dashboard.subscription.update_information')
          }}</span>
        </div>
      </div>
      <v-row class="px-5 py-3">
        <v-col cols="12" md="6">
          <span>{{ $t('dashboard.subscription.company_label') }}:</span><br />
          <span v-if="companyInfo.nameCompany">{{
            companyInfo.nameCompany
          }}</span>
          <span v-else class="text-disabled">{{
            $t('common.notSpecified')
          }}</span>
        </v-col>
        <v-col cols="12" md="6">
          <span>{{ $t('dashboard.subscription.siret') }}:</span><br />
          <span v-if="companyInfo.siret">{{ companyInfo.siret }}</span>
          <span v-else class="text-disabled">{{
            $t('common.notSpecified')
          }}</span>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            {{ $t('dashboard.subscription.adresse') }}: <br />
            <template v-if="!!companyInfo.addressCompany">
              {{ companyInfo.addressCompany }},
              {{ companyInfo.postalCodeCompany }}
              {{ companyInfo.cityCompany }}
            </template>
            <template v-else>
              <div class="text-disabled">
                {{ $t('common.notSpecified') }}
              </div>
            </template>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <span>{{ $t('dashboard.subscription.tva_number') }}:</span>
            <br />
            <span v-if="companyInfo.tvaNumberCompany">{{
              companyInfo.tvaNumberCompany
            }}</span>
            <span v-else class="text-disabled">{{
              $t('common.notSpecified')
            }}</span>
          </div>
        </v-col>
      </v-row>
      <template #actions>
        <p class="text-disabled text-subtitle-2">
          {{ $t('dashboard.subscription.information_desc') }}
        </p>
      </template>
    </v-card>

    <v-card color="white" class="mt-5 elevation-0 border">
      <div
        class="bg-gray-100 py-1 px-5 d-md-flex justify-space-between form-header text-primary"
      >
        <div class="align-center">
          <v-icon>mdi-cash</v-icon>
          <span class="ml-1">{{
            $t('dashboard.subscription.payment_method')
          }}</span>
        </div>
        <div
          class="d-flex align-center cursor-pointer"
          @click="updateCart = true"
        >
          <v-icon size="15px" color="blue">mdi-pencil-box-outline</v-icon>
          <span class="ml-1">{{ $t('dashboard.subscription.update_cb') }}</span>
        </div>
      </div>
      <v-row class="px-5 py-3">
        <v-col cols="12">
          <span v-if="subscriptionStore.cardData.lastFour"
            >**** **** **** {{ subscriptionStore.cardData.lastFour }}</span
          >
          <span
            :class="
              subscriptionStore.subscriptionStatus['isLifetime']
                ? 'text-disabled'
                : 'text-error'
            "
            v-else
          >
            <span v-if="subscriptionStore.subscriptionStatus['isLifetime']">{{
              $t('dashboard.subscription.add_payment_card_islife')
            }}</span>
            <span v-else>{{
              $t('dashboard.subscription.add_payment_card')
            }}</span>
          </span>
        </v-col>
        <v-col v-if="subscriptionStore.cardData.expiryMonth" cols="4">
          <span
            >{{ $t('dashboard.subscription.expiration') }}:
            {{
              String(subscriptionStore.cardData.expiryMonth).padStart(2, '0')
            }}/{{ subscriptionStore.cardData.expiryYear }}</span
          >
        </v-col>
      </v-row>
      <template #actions v-if="paymentTotalItems == 1">
        <p class="text-disabled text-subtitle-2">
          {{ $t('dashboard.subscription.payment_desc') }}
        </p>
      </template>
    </v-card>

    <!-- <v-row>
    <v-col cols="12">
      <v-card color="white pa-3">
        <v-row v-show="subscriptionMonthsAlreadyPaid !== null">
          <v-col cols="12" md="6" order="1" order-md="0">
            <div v-if="!isLifetime">
              <span class="font-weight-bold">{{
                $t('dashboard.subscription.preSubscribedMonthsAsked')
              }}</span>
              {{ subscriptionMonthsAsked }}
            </div>
            <div>
              <span class="font-weight-bold mr-1"
                >{{ $t('dashboard.subscription.preSubscribedMonthsPaid') }}
              </span>
              <span v-if="!isLifetime">
                {{ subscriptionMonthsAlreadyPaid || 0 }}</span
              >
              <span v-else>{{ $t('dashboard.subscription.lifetime') }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center justify-md-end"
            order="0"
            order-md="1"
          >
            <v-btn
              v-if="!isLifetime"
              color="secondary"
              @click="editDialog = true"
              class="mt-3 mt-md-0"
            >
              {{ $t('dashboard.subscription.extendButton') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row> -->

    <v-row>
      <v-col cols="12">
        <v-card color="white" class="elevation-0 border">
          <div
            class="px-5 d-flex align-center form-header text-primary bg-gray-100"
          >
            {{ $t('dashboard.subscription.payments.title') }}
          </div>
          <!--        @ts-ignore-->
          <v-data-table-server
            class="bg-white"
            :items="formattedPaymentList"
            :headers="paymentHeaders"
            :items-per-page="paymentItemsPerPage"
            :items-length="paymentTotalItems"
            :items-per-page-options="[10, 20, 30]"
            :loading="paymentListLoading"
            :no-data-text="$t('dashboard.subscription.payments.noPayment')"
            @update:options="onPaymentTableUpdate"
          >
            <template #item.status="{ item }">
              <v-chip :color="item.statusColor" dar rounded>
                {{ item.status }}
              </v-chip>
            </template>
            <template #item.invoice="{ item }">
              <v-btn
                v-if="item.statusColor != 'error'"
                :loading="donwloading[item.id]"
                @click="() => downloadInvoice(item.id)"
                variant="plain"
                color="blue"
                density="compact"
                icon="mdi-open-in-new"
              ></v-btn>
            </template>
          </v-data-table-server>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline bg-secondary">
          {{ $t('dashboard.subscription.extend_your_subscription') }}
        </v-card-title>
        <SubscriptionForm
          ref="subscriptionForm"
          :key="subscriptionFormKey"
          @submitting="editSubmitting = $event"
          @finished="editFinished = $event"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="!editFinished">
            <v-btn variant="text" @click="editDialog = false">
              {{ $t('preSignupDialog.backButton') }}
            </v-btn>
            <v-btn
              color="secondary"
              variant="flat"
              @click="submitEditSubscription"
              :loading="editSubmitting"
            >
              {{ $t('preSignupDialog.submitButton') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn color="secondary" variant="flat" @click="closeEditDialog">
              {{ $t('preSignupDialog.closeButton') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CompanyDialog
      v-if="companyInfoDialog"
      :dialog="companyInfoDialog"
      :infos="companyInfo"
      @update:company="updateCompanyInfo($event)"
      @update:close="companyInfoDialog = false"
      :loading="loadingInfo"
    />

    <BreakDialog
      :loading="break_loading"
      :dialog="toggleBreakDialog"
      @update:break="handleBreak($event)"
      @update:close="toggleBreakDialog = false"
    />

    <UpdateCardDialog
      v-if="updateCart"
      @update:close="closeModalDialog"
      :dialog="updateCart"
      :cardInfo="cardInfo"
    />

    <CancelSubscription
      :dialog="cancelPlan"
      @update:confirm="handleCancel"
      @update:cancel="cancelPlan = false"
      :loading="loadingCancel"
    />
  </FormBlock>
</template>

<script lang="ts" setup>
import SubscriptionForm from '@/components/forms/subscription/SubscriptionForm.vue'
import { onMounted, ref, computed, Ref } from 'vue'
import { useAppStore } from '@/store/app'
import createHttpClient from '@/api/httpClient'
import { useI18n } from 'vue-i18n'
import FormBlock from '@/components/common/FormBlock.vue'
import RecapPlan from '@/components/RecapPlan.vue'
import CompanyDialog from '@/components/dialog/CompanyDialog.vue'
import BreakDialog from '@/components/dialog/BreakDialog.vue'
import UpdateCardDialog from '@/components/dialog/UpdateCardDialog.vue'
import CancelSubscription from '@/components/dialog/CancelSubscription.vue'
import MessageService from '@/components/feedback/message/messageService'
import { DashboardStatus } from '@/utils/dashboardStatus'
import { useUserStore } from '@/store/user/userStore'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'

import { useUserTvaStore } from '@/store/tva/userTvaStore'

const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()
const { t: $t, d, n } = useI18n()
const editDialog = ref(false)
const editSubmitting = ref(false)
const subscriptionForm = ref(null)
const editFinished = ref(false)
const subscriptionFormKey = ref(0)
const appStore = useAppStore()
const subscriptionMonthsAlreadyPaid = ref(null)
const subscriptionMonthsAsked = ref(0)
const isLifetime = ref(false)
const companyInfoDialog = ref(false)
const paymentList = ref([])
const paymentListLoading = ref(false)
const paymentItemsPerPage = 10
const paymentHeaders = [
  { title: 'Date', value: 'createdAt', align: 'left' },
  { title: 'Montant', value: 'amount', align: 'center' },
  { title: 'Statut', value: 'status', align: 'center' },
  { title: '', value: 'invoice', align: 'center' },
] as any
const paymentTotalItems = ref(0)
const preSubActive = ref(false)
const formattedPaymentList = computed(() => {
  return paymentList.value.map(payment => {
    return {
      ...payment,
      createdAt: d(new Date(payment.createdAt), 'short'),
      amount: n(payment.amountCents / 100, 'currency'),
      status: getPaymentStatus(payment.status).text,
      statusColor: getPaymentStatus(payment.status).color,
    }
  })
})

const _actualPrice = computed(() => {
  return (
    subscriptionStore.subscriptionStatus['actualPrice'] ??
    userTva.applyTva(50, userTva.countryTva?.tva)
  )
})

const _price = computed(() => {
  return (
    subscriptionStore.subscriptionStatus['price'] ??
    userTva.countryTva?.price / 100
  )
})

const userTva = useUserTvaStore()

const closeModalDialog = () => {
  updateCart.value = false
  fetchPaymentMethod()
}
const subscriptionStatus = ref({
  isActive: false,
  isCancel: false,
  isBreak: false,
})

const isBreak = ref(false)
const updateCart = ref(false)
const break_loading = ref(false)
const submitEditSubscription = () => {
  subscriptionForm.value?.submit()
}
// const isSubscribed = ref(true)
const loadingInfo = ref(false)
const companyInfo = ref({
  nameCompany: '',
  addressCompany: '',
  siret: '',
  postalCodeCompany: '',
  cityCompany: '',
  countryCompany: '',
  tvaNumberCompany: '',
})
const cardInfo = ref({
  expiryMonth: '',
  expiryYear: '',
  lastFour: '',
})

const toggleBreakDialog = ref(false)
const cancelPlan = ref(false)
const closeEditDialog = async () => {
  editDialog.value = false
  fetchInformation()
  fetchPayments()
  setTimeout(() => {
    subscriptionFormKey.value++
    editFinished.value = false
  }, 1000)
}

const fetchInformation = async () => {
  const httpClient = createHttpClient()
  appStore.setInnerLoading(true)
  httpClient
    .get('/subscription/current-state')
    .then(response => {
      subscriptionMonthsAsked.value = response.data.totalMonthsAsked
      subscriptionMonthsAlreadyPaid.value = response.data.monthsAlreadyPaid
      isLifetime.value = !!response.data.isLifetime
      subscriptionStatus.value = { ...response.data.subscriptionStatus } // status des différents subscription d'un abonnement
    })
    .finally(() => {
      appStore.setInnerLoading(false)
    })
}

const fetchPayments = (page = 1, itemsPerPage = 10) => {
  const httpClient = createHttpClient()
  const offset = (page - 1) * itemsPerPage
  paymentListLoading.value = true
  httpClient
    .get(`/payment/mines?limit=${itemsPerPage}&offset=${offset}`)
    .then(response => {
      paymentList.value = response.data.payments
      paymentTotalItems.value = response.data.count
    })
    .finally(() => {
      paymentListLoading.value = false
    })
}

const getPaymentStatus = status => {
  switch (status) {
    case 'succeeded':
      return {
        text: $t('dashboard.subscription.payments.status.succeeded'),
        color: 'success',
      }
    case 'pending':
      return {
        text: $t('dashboard.subscription.payments.status.pending'),
        color: 'warning',
      }
    case 'refunded':
      return {
        text: $t('dashboard.subscription.payments.status.refunded'),
        color: 'error',
      }
    case 'failed':
    default:
      return {
        text: $t('dashboard.subscription.payments.status.failed'),
        color: 'error',
      }
  }
}

const onPaymentTableUpdate = ({ page, itemsPerPage }) => {
  fetchPayments(page, itemsPerPage)
}

// update information
const updateCompanyInfo = async payload => {
  if (loadingInfo.value) return false
  try {
    loadingInfo.value = true
    const httpClient = createHttpClient()
    const { data } = await httpClient.post(
      '/account/billing-informations',
      payload,
    )

    companyInfo.value = {
      ...companyInfo.value,
      nameCompany: data.nameCompany,
      addressCompany: data.addressCompany,
      siret: data.siret,
      countryCompany: data.countryCompany,
      cityCompany: data.cityCompany,
      postalCodeCompany: data.postalCodeCompany,
      tvaNumberCompany: data.tvaNumberCompany,
    }
    MessageService.success(
      $t('dashboard.subscription.messages.informations.success'),
    )
    companyInfoDialog.value = false
    userTva.getUserTva()
    fetchCurrentSubscriptionStatus()
  } catch {
    MessageService.error(
      $t('dashboard.subscription.messages.informations.error'),
    )
  } finally {
    loadingInfo.value = false
  }
}

//  mettre l'abonnement praticien en pause
const handleBreak = async payload => {
  if (break_loading.value) return false
  const httpCLient = createHttpClient()

  try {
    break_loading.value = true
    await httpCLient.post('/subscription/pause', payload)
    MessageService.success($t('dashboard.subscription.messages.break.success'))
    fetchCurrentSubscriptionStatus()
    userStore.refreshToken()
    // subscriptionStatus.value = { ...subscriptionStatus.value, isBreak: true }
  } catch (err) {
    MessageService.error($t('dashboard.subscription.messages.break.error'))
  } finally {
    toggleBreakDialog.value = false
    break_loading.value = false
  }
}

const loadingCancel = ref(false)
//  resiliation d'abonnement
const handleCancel = async () => {
  if (loadingCancel.value) return false
  loadingCancel.value = true
  try {
    const req = createHttpClient()
    await req.post('/subscription/deactive-subscription')
    MessageService.success(
      $t('dashboard.subscription.messages.desactive.success'),
    )
    userStore.refreshToken()
    fetchCurrentSubscriptionStatus()
  } catch (err) {
    MessageService.error($t('dashboard.subscription.messages.desactive.error'))
  } finally {
    cancelPlan.value = false
    loadingCancel.value = false
  }
}

const fetchCompanyInfo = async () => {
  const req = createHttpClient()
  const { data } = await req.get('/account/informations')
  companyInfo.value = {
    ...companyInfo.value,
    nameCompany: data.nameCompany,
    addressCompany: data.addressCompany,
    siret: data.siret,
    countryCompany: data.countryCompany,
    cityCompany: data.cityCompany,
    postalCodeCompany: data.postalCodeCompany,
    tvaNumberCompany: data.tvaNumberCompany,
  }
}

const fetchPaymentMethod = async () => {
  const resp = await subscriptionStore.fetchCardInfo()
}

const subscriptionAvailable = async () => {
  const request = createHttpClient()
  const resp = await request.get('/subscription/available-subscription')
}

const subscriptionload = ref(true)

const displayAmount = computed(() => {
  if (
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0 &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 1 &&
    subscriptionStore.subscriptionStatus['status'] == 'active'
  ) {
    return true
  }
})

const isSubscribed = computed(() => {
  if (subscriptionStore.subscriptionStatus['type'] === 'pre_subscription') {
    return false
  }

  if (
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0 &&
    subscriptionStatus.value['discountMonthsRemaining'] == null
  ) {
    preSubActive.value = true
    return false
  }

  if (
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 1 &&
    subscriptionStore.subscriptionStatus['status'] == 'active'
  ) {
    preSubActive.value = true
    return false
  }
})

const countryTva = computed(() => {
  return subscriptionStore.subscriptionStatus['tva'] ?? userTva.countryTva?.tva
})

// status de l'abonement en cour du praticien
const fetchCurrentSubscriptionStatus = async () => {
  try {
    subscriptionload.value = true
    // fetch subscription status
    await subscriptionStore.fetchSubscriptionStatus()
  } catch (err) {
  } finally {
    subscriptionload.value = false
  }
}

const canActive = ref()

const dashBoardState = async () => {
  const req = createHttpClient()

  const resp = await req.get('/account/dashboard-status')

  canActive.value = DashboardStatus(resp.data)
}

onMounted(() => {
  userTva.getUserTva()

  fetchInformation()
  fetchPayments()
  fetchCompanyInfo()
  fetchPaymentMethod()
  subscriptionAvailable()
  fetchCurrentSubscriptionStatus()

  dashBoardState()
})

const donwloading = ref({})

const downloadInvoice = async (uuid: string) => {
  if (donwloading.value[uuid]) return false
  try {
    donwloading.value = { ...donwloading.value, [uuid]: true }
    const req = createHttpClient()

    const { data } = await req.get(`/payment/download-receipt/${uuid}`, {
      responseType: 'blob',
    })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(data)
    link.download = 'Abonnement Holitime.pdf'
    document.body.appendChild(link) // Nécessaire pour Firefox
    link.click()

    setTimeout(() => {
      URL.revokeObjectURL(link.href)
    }, 100)
  } catch (err) {
  } finally {
    donwloading.value = { ...donwloading.value, [uuid]: false }
  }
}
</script>

<style lang="scss"></style>
