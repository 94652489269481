<template>
  <v-dialog
    :model-value="dialogModelValue"
    persistent
    max-width="800px"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>
          {{
            props.isEdit
              ? $t('dashboard.time-slot.dialogMotive.editMotiveTitle')
              : $t('dashboard.time-slot.dialogMotive.createMotiveTitle')
          }}
          {{
        }}</span>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close"
          @click="dialogModelValue = false"
        ></v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column ga-5 px-10 xxs-padding">
        <v-select
          v-model="selectedProfile"
          :error-messages="errorMessage('selectedProfile')"
          :items="profileList"
          class="e"
          variant="underlined"
          :label="$t('dashboard.time-slot.dialogMotive.selectProfile')"
          item-title="mainDiscipline.name"
          item-value="id"
          return-object
        >
          <template #append-inner="{}">
            <v-btn
              v-show="loadingProfiles"
              :loading="loadingProfiles"
              variant="text"
              icon="mdi-loading"
              disabled
            >
            </v-btn>
          </template>
        </v-select>

        <VCombobox
          v-model="selectedMotive"
          variant="underlined"
          :label="
            $t(
              'dashboard.time-slot.dialogMotive.placeholderSelectAppointmentReason',
            )
          "
          :rounded="false"
        >
          <template v-slot:append-inner="{}">
            <VIcon icon="mdi-checkbox-marked" color="green" />
            <VIcon icon="mdi-chevron-down" />
          </template>
        </VCombobox>
        <div class="d-flex align-center ga-2">
          <VIcon icon="mdi-currency-eur" />
          {{ $t('dashboard.time-slot.dialogMotive.tarif') }}
          <VTextField
            v-model="price"
            density="compact"
            :error-messages="errorMessage('price')"
            :hide-details="errorMessage('price') ? false : true"
            class="mr-4"
            :placeholder="
              $t('dashboard.time-slot.dialogMotive.pricePlaceholder')
            "
          />
          <VIcon icon="mdi-clock-outline" />
          {{ $t('dashboard.time-slot.dialogMotive.duration') }}
          <VTextField
            outlined
            density="compact"
            :error-messages="errorMessage('duration')"
            v-model="duration"
            :hide-details="errorMessage('duration') ? false : true"
            :placeholder="
              $t('dashboard.time-slot.dialogMotive.timePlaceholder')
            "
          />
        </div>
        <div class="d-flex align-center ga-2 justify-between">
          <span class="breakBlockMobile">{{
            $t('dashboard.time-slot.dialogMotive.suggestRemote')
          }}</span>
          <VRadioGroup
            v-model="remoteConsultation"
            :error-messages="errorMessage('remoteConsultation')"
            inline
            :hide-details="errorMessage('remoteConsultation') ? false : true"
            class="breakBlockMobile"
          >
            <div class="d-flex align-center">
              <VRadio color="blue" class="pl-6" value="yes" />
              {{ $t('dashboard.time-slot.yes') }}
              <VRadio color="blue" class="pl-6" value="no" />
              {{ $t('dashboard.time-slot.no') }}
            </div>
          </VRadioGroup>
        </div>

        <div class="d-flex align-center justify-between">
          {{ $t('dashboard.time-slot.dialogMotive.suggestBreak') }}
          <VCheckbox
            color="blue"
            hide-details
            v-model="doBreakBetweenConsultations"
            :value="true"
            class="breakBlockMobile"
          />
          <v-tooltip width="200px">
            <template v-slot:activator="{ props }">
              <VIcon
                v-bind="props"
                icon="mdi-help-circle-outline"
                color="grey"
              />
            </template>
            {{ $t('dashboard.time-slot.dialogMotive.tooltipHelpBreak') }}
          </v-tooltip>
        </div>
        <VSelect
          v-if="doBreakBetweenConsultations"
          density="compact"
          v-model="breakDuration"
          :items="breakTimeList"
          item-title="title"
          :placeholder="
            $t('dashboard.time-slot.dialogMotive.placeholderBreakTime')
          "
          item-value="value"
        />
        <p class="text-caption font-italic">
          {{ $t('dashboard.time-slot.dialogMotive.attachedToSelectedProfile') }}
        </p>
      </v-card-text>
      <v-card-actions class="pr-5 pb-5">
        <v-spacer></v-spacer>
        <v-btn
          v-if="isEdit"
          color="red"
          variant="flat"
          @click="openCancelConfirm"
          >{{ t('dashboard.time-slot.dialogMotive.deleteMotive') }}</v-btn
        >
        <v-btn
          v-if="isEdit"
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="loading"
          :disabled="!validForm"
          @click="updateMotive"
        >
          {{ $t('dashboard.time-slot.dialogMotive.update') }}
        </v-btn>
        <v-btn
          v-if="!isEdit"
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="loading"
          :disabled="!validForm"
          @click="saveMotive"
        >
          {{ $t('dashboard.time-slot.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import createHttpClient from '@/api/httpClient'
import { PropType, Ref, computed, ref, watch } from 'vue'
import MessageService from '../feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import { getProfiles } from '@/api/profile/profile'
import { ProfileInformationData } from '@/api/profile/profile.d'
import {
  AppointmentReasonParams,
  FormattedAppointmentReasonInformationData,
} from '@/api/appointment-reason/appointment-reason'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'

const { t } = useI18n()
const dialogModelValue: Ref<boolean> = defineModel('modelValue')
const selectedMotive: Ref<string> = ref(null)
const httpClient = createHttpClient()
const appointmentReasonStore = useAppointmentReasonStore()
const props = defineProps({
  isEdit: {
    type: Boolean,
    required: false,
  },
  motiveData: {
    type: Object as PropType<FormattedAppointmentReasonInformationData>,
    required: false,
  },
  defaultSelectedProfileId: {
    type: String,
    required: false,
  },
})

const price = ref(null)
const duration = ref(null)
const errors = ref({})

const remoteConsultation = ref('no')
const doBreakBetweenConsultations = ref(false)
const breakDuration = ref(null)
const breakTimeList = Array.from({ length: 60 }, (_, i) => {
  const totalMinutes = (i + 1) * 5
  const heures = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  const display = `${heures ? heures + ' h' : ''} ${minutes ? minutes + ' minutes' : ''}`
  return { title: display, value: totalMinutes }
})

/** Manage profiles */
const profileList: Ref<ProfileInformationData[]> = ref([])
const selectedProfile: Ref<ProfileInformationData> = ref(null)
const loadingProfiles = ref(false)
const onOpen = async () => {
  loadingProfiles.value = true

  const { data } = await getProfiles()
  profileList.value = data
  selectedProfile.value =
    data.find(profile => profile.id === props.defaultSelectedProfileId) ??
    data[0]

  // Case editing select th profile of the reason
  if (props.motiveData && props.motiveData.profile.id) {
    selectedProfile.value = profileList.value.find(
      p => p.id == props.motiveData.profile.id,
    )
  }

  loadingProfiles.value = false
}

watch(dialogModelValue, (newval, oldval) => {
  if (newval) {
    onOpen()
  }
})
/** end manage profiles */

const errorMessage = key => {
  return errors.value[key] ? errors.value[key][0] : ''
}

const validForm = computed(() => {
  if (!selectedMotive.value || !price.value || !duration.value) {
    return false
  } else return true
})
const loading = ref(false)
const saveMotive = async () => {
  loading.value = true
  try {
    const res = await httpClient.post(
      '/appointment-reason/',
      {
        label: selectedMotive.value,
        description: null,
        price: parseFloat(price.value.replace(/[€,]/g, '')),
        profile: selectedProfile.value.id,
        duration: duration.value,
        breakDuration: doBreakBetweenConsultations.value
          ? breakDuration.value
          : null,
        remote: remoteConsultation.value == 'yes',
        presential: true,
      } as AppointmentReasonParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (res.status != 200) {
      throw Error(JSON.stringify(res))
    }
    const dialogTimeSlotStore = useDialogTimeSlotStore()
    appointmentReasonStore.appointmentReasons.push(res.data)
    if (Array.isArray(dialogTimeSlotStore.selectedAppointmentReasons)) {
      dialogTimeSlotStore.selectedAppointmentReasons.push(res.data)
    } else {
      dialogTimeSlotStore.selectedAppointmentReasons = [res.data]
    }

    MessageService.success(
      t('dashboard.time-slot.dialogMotive.successCreateAppointmentReason'),
    )
    dialogModelValue.value = false
  } catch (error) {
    MessageService.error(
      t('dashboard.time-slot.dialogMotive.errorCreateAppointmentReason'),
    )
    errors.value = error.response.data
    console.error(error)
  }
  setInitialValue()
  loading.value = false
}
const setInitialValue = () => {
  price.value = null
  duration.value = null
  breakDuration.value = null
  selectedMotive.value = null
  selectedProfile.value = profileList.value[0]
}

// on open
watch(
  () => props.isEdit,
  (newValue, oldValue) => {
    if (!newValue) {
      setInitialValue()
    }
  },
)
// On open edit
watch(
  () => props.motiveData?.id,
  (newValue, oldValue) => {
    if (newValue) {
      price.value = props.motiveData ? props.motiveData.price : null
      duration.value = props.motiveData ? props.motiveData.duration : null
      if (props.motiveData.breakDuration > 0) {
        doBreakBetweenConsultations.value = true
      }
      breakDuration.value = props.motiveData
        ? props.motiveData.breakDuration
        : null
      selectedMotive.value = props.motiveData ? props.motiveData.label : null
      remoteConsultation.value = props.motiveData.remote
      selectedProfile.value = profileList.value.find(
        p => p.id === props.motiveData.profile.id,
      )
    } else {
      setInitialValue()
    }
  },
)
const updateMotive = async () => {
  try {
    const res = await httpClient.put(
      '/appointment-reason/' + props.motiveData.id,
      {
        label: selectedMotive.value,
        price: price.value,
        profile: selectedProfile.value.id,
        description: null,
        duration: duration.value,
        breakDuration: doBreakBetweenConsultations.value
          ? breakDuration.value
          : null,
        remote: remoteConsultation.value == 'yes',
      } as AppointmentReasonParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (res.status != 200) {
      throw Error(JSON.stringify(res))
    }

    appointmentReasonStore.fillAppointmentReasons()
    MessageService.success(
      t('dashboard.time-slot.dialogMotive.successEditAppointmentReason'),
    )
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
  dialogModelValue.value = false
}

const openCancelConfirm = () => {
  ConfirmDialogService.confirm({
    title: t('dashboard.time-slot.dialogMotive.confirmDeleteTitle'),
    message: t('dashboard.time-slot.dialogMotive.confirmDeleteMessage'),
    optionsConfirm: {
      onConfirm: () => {
        handleCancelAppointment()
      },
      onCancel: () => {},
    },
  })
}
const handleCancelAppointment = async () => {
  try {
    const deleteResponse = await httpClient.delete(
      '/appointment-reason/' + props.motiveData.id,
    )
    if (deleteResponse.status !== 200) {
      throw Error(JSON.stringify(deleteResponse))
    }
    MessageService.success(
      t('dashboard.time-slot.dialogMotive.successDeleteMotive'),
    )
    appointmentReasonStore.fillAppointmentReasons()
    dialogModelValue.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
</style>
<style>
@media (max-width: 768px) {
  .breakBlockMobile {
    display: block;
  }
  .xxs-padding {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
</style>
