interface IDashboard {
  myAccount: boolean
  mySubscription: boolean
  myProfils: boolean
  myAddresses: boolean
  myTimeslots: boolean
}

export function DashboardStatus(status: IDashboard) {
  const check =
    Number(status.myAccount) *
    Number(status.myProfils) *
    Number(status.myAddresses) *
    Number(status.myTimeslots)

  return !!check
}
