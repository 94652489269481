<template>
  <v-menu v-model="profileMenuOpen" transition="scale-transition">
    <template v-slot:activator="{ props }">
      <div
        class="select-profile d-flex align-center cursor-pointer"
        v-bind="props"
      >
        {{
          dialogStore.selectedProfile != null &&
          dialogStore.selectedProfile.mainDiscipline
            ? dialogStore.selectedProfile.mainDiscipline.name
            : $t('dashboard.time-slot.dialogTimeSlot.placeholderProfile')
        }}
        <v-icon
          class="transition-smooth"
          :class="{ 'rotated-angle': profileMenuOpen }"
          color="secondary"
          icon="mdi-chevron-down"
        />
      </div>
    </template>
    <v-list>
      <v-list-item
        :value="profile"
        v-for="(profile, i) in userStore.profiles"
        :key="profile.tagline + '-' + i"
        @click="handleSelectProfile(profile)"
      >
        <v-list-item-title>
          {{ profile.mainDiscipline.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-menu v-model="addressMenuOpen" transition="scale-transition">
    <template v-slot:activator="{ props }">
      <div
        class="select-address d-flex align-center cursor-pointer"
        v-bind="props"
      >
        {{
          dialogStore.selectedAddress != null
            ? truncateText(addressDisplay)
            : $t('dashboard.time-slot.dialogTimeSlot.placeholderAddress')
        }}
        <v-icon
          class="transition-smooth"
          :class="{ 'rotated-angle': addressMenuOpen }"
          color="secondary"
          icon="mdi-chevron-down"
        />
      </div>
    </template>
    <v-list>
      <v-list-item
        :value="address"
        v-for="address in userStore.addresses"
        :key="address.addressLine"
        @click="handleSelectAddress(address)"
      >
        <v-list-item-title>
          {{ formatAddress(address, true) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useUserStore } from '@/store/user/userStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { truncateText } from '@/utils/truncate'
import { formatAddress } from '@/utils/formatAddress'

const userStore = useUserStore()
const dialogStore = useDialogTimeSlotStore()
const emit = defineEmits(['update:profile', 'update:address'])

const profileMenuOpen = ref(false)
const handleSelectProfile = profile => {
  dialogStore.selectedProfile = profile
  dialogStore.saveToLocalStorage(profile, dialogStore.selectedAddress)
  emit('update:profile')
}

const addressDisplay = computed(() => {
  return formatAddress(dialogStore.selectedAddress, true) //`${dialogStore.selectedAddress.addressLine}, ${dialogStore.selectedAddress.postalCode} ${dialogStore.selectedAddress.city}`
})
const addressMenuOpen = ref(false)
const handleSelectAddress = address => {
  dialogStore.selectedAddress = address
  dialogStore.saveToLocalStorage(dialogStore.selectedProfile, address)
  emit('update:address')
}
</script>

<style scoped>
@import url('./style.css');

.select-address {
  border: 1px solid #aaa;
  border-radius: 0 100px 100px 0 !important;
  background-color: #f4f4f4;
  position: relative;
  border-left: none;
  padding: 10px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  flex: 0 1 0%;
  &:hover {
    background-color: #eee;
  }
}

.select-profile {
  border: 1px solid #aaa;
  border-radius: 100px 0 0 100px !important;
  background-color: #f4f4f4;
  position: relative;
  border-right: none;
  padding: 10px;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #aaa;
  }
  &:hover {
    background-color: #eee;
  }
}
.select-profile-option-wrapper {
  position: absolute;
  top: 117px;
  z-index: 999;
  & .select-profile-option {
    background-color: #f4f4f4;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}
</style>
<style>
@media (max-width: 768px) {
  .select-profile,
  .select-address {
    padding: 8px !important;
    font-size: 12px;
  }
}
</style>
