import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import { SubscriptionStatusResponse } from './subscription.d'

const httpClient = createHttpClient()

export const getSubscription = async (): Promise<
  ApiResponse<SubscriptionStatusResponse[]>
> => {
  const response: AxiosResponse<SubscriptionStatusResponse[]> =
    await httpClient.get<SubscriptionStatusResponse[]>('/subscription/status')
  return {
    status: response.status,
    data: response.data,
  }
}
