<template>
  <v-navigation-drawer
    v-if="mdAndDown"
    :model-value="sidebarState"
    :temporary="true"
    absolute
    class="custom-nav-drawer d-flex flex-column fill-height ga-5 pa-4"
    width="360"
    color="white"
    @update:model-value="updateSidebarState(!sidebarState)"
  >
    <CustomSidebar />
  </v-navigation-drawer>
  <div v-else-if="lgAndUp" class="d-flex flex-column fill-height ga-5 pa-4">
    <CustomSidebar />
  </div>
</template>
<script lang="ts" setup>
import { useDisplay } from 'vuetify'
import {
  useHeaderState,
  useSidebarState,
} from '@/components/calendar/calendarState'
import CustomSidebar from '@/components/calendar/CustomSidebar.vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { lgAndUp, mdAndDown } = useDisplay()
const { sidebarState, updateSidebarState } = useSidebarState()
const route = useRoute()

const headerState = useHeaderState()
const { t } = useI18n()

onMounted(() => {
  if (route.name === 'DashboardTimeSlot') {
    headerState.updateHeaderState(t('dashboard.menu.appointment'))
  }
  if (route.name === 'DashboardAppointment') {
    headerState.updateHeaderState(t('dashboard.menu.timeslot'))
  }
})
</script>

<style>
.sx__calendar-content {
  position: relative;
  transition: all 0.3s ease-out;
  overflow-x: hidden;
}

.sx__calendar-content.hidden-sidebar {
  margin-left: -360px;
}
.custom-nav-drawer {
  top: 0 !important;
  height: 100% !important;
}
.sx__week-grid {
  cursor: pointer;
}
</style>
