import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { SubscriptionStatusResponse } from '@/api/subscription/subscription.d'
import { ApiResponse } from '@/api/api'
import { getSubscription } from '@/api/subscription/subscription'
import i18n from '@/plugins/i18n'
import { AxiosResponse } from 'axios'
import { ICardInfo } from './CardInfo.d'
import createHttpClient from '@/api/httpClient'
import { InformationData } from '@/api/account/information.d'
import MessageService from '@/components/feedback/message/messageService'
import { useUserStore } from '../user/userStore'
import { useUserTvaStore } from '../tva/userTvaStore'

const { t } = i18n.global

const httpClient = createHttpClient()

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscriptionStatus: Ref<SubscriptionStatusResponse[]> = ref([])
  const displayModal = ref(false)
  const displayPresubModal = ref(false)
  const cardData = ref<ICardInfo>()
  const userInformation = ref<InformationData>()
  const userStore = useUserStore()
  const userTva = useUserTvaStore()
  const activationLoad = ref(false)

  // fetch subscription status
  const fetchSubscriptionStatus = async () => {
    try {
      const res: ApiResponse<SubscriptionStatusResponse[]> =
        await getSubscription()
      if (res.status !== 200) throw Error(JSON.stringify(res))

      // const resp = res.data

      subscriptionStatus.value = res.data

      // message for practitioners who registered during the pre-launch period and did not subscribe
      if (subscriptionStatus.value['monthsAlreadyPaid'] == 0) {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.subscription_message_no_subscription_active')}`
      }

      if (subscriptionStatus.value['type'] === 'subscription') {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.subscription_message', { price: subscriptionStatus.value['price'], reduction: subscriptionStatus.value['discount'] ?? 0, monthsRemaining: subscriptionStatus.value['discountMonthsRemaining'] || 0, nextprice: subscriptionStatus.value['price'], tva: subscriptionStatus.value['tva'] > 0 ? 'TTC' : 'HT' })}`
        if (subscriptionStatus.value['isLifetime']) {
          subscriptionStatus.value['message'] =
            `${t('dashboard.subscription.lifetime')}`
        }
      }

      if (subscriptionStatus.value['type'] === 'pre_subscription') {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.pre_subscription_message2', { value: subscriptionStatus.value['monthsAlreadyPaid'] })}`

        // if (
        //   subscriptionStatus.value['monthsAlreadyPaid'] == null &&
        //   subscriptionStatus.value['discountMonthsRemaining'] == null
        // ) {
        //   subscriptionStatus.value['message'] =
        //     `${t('dashboard.subscription.subscription_message_no_subscription_active')}`
        // }

        if (subscriptionStatus.value['isLifetime']) {
          subscriptionStatus.value['message'] =
            `${t('dashboard.subscription.lifetime')}`
        }
      }

      if (subscriptionStatus.value['monthsAlreadyPaid'] > 1) {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.pre_sub_active_msg', { months: subscriptionStatus.value['monthsAlreadyPaid'] })}`
      }

      if (
        subscriptionStatus.value['monthsAlreadyPaid'] > 0 &&
        subscriptionStatus.value['discountMonthsRemaining'] == null
      ) {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.pre_subscription_message2', { value: subscriptionStatus.value['monthsAlreadyPaid'] })}`
        if (subscriptionStatus.value['isLifetime']) {
          subscriptionStatus.value['message'] =
            `${t('dashboard.subscription.lifetime')}`
        }
      }

      if (
        subscriptionStatus.value['status'] === 'trialing' &&
        subscriptionStatus.value['monthsAlreadyPaid'] > 1
      ) {
        subscriptionStatus.value['message'] =
          `${t('dashboard.subscription.pre_subscription_message_activated', { months: subscriptionStatus.value['monthsAlreadyPaid'] })}`
      }

      // subscriptionStatus.value = resp
    } catch {}
  }

  const toggleModal = () => {
    displayModal.value = !displayModal.value
  }

  const togglePreModal = () => {
    displayPresubModal.value = !displayPresubModal.value
  }

  const fetchCardInfo = async () => {
    try {
      const res: ApiResponse<ICardInfo> = await httpClient.get(
        '/payment/payment-method',
      )
      cardData.value = res.data
    } catch {}
  }

  const updateCardLocally = (payload: ICardInfo) => {
    cardData.value = payload
  }

  const updateUserCard = async (pm_id: string) => {
    if (!pm_id) return false
    try {
      await httpClient.put('/payment/create-payment-method', {
        paymentMethodId: pm_id,
      })
      await fetchCardInfo()
      return true
    } catch {
      return false
    }
  }

  const fetchInformation = async () => {
    try {
      const res: ApiResponse<InformationData> = await httpClient.get(
        '/account/informations',
      )
      userInformation.value = res.data
    } catch {}
  }

  const updateInformation = async (payload: InformationData) => {
    userInformation.value = { ...payload }
    try {
      const data = {
        countryCompany: userInformation.value.countryCompany,
        tvaNumberCompany: userInformation.value.tvaNumberCompany,
      }
      await httpClient.post('/account/billing-informations', data)
      await fetchInformation()
      await userTva.getUserTva()
      return true
    } catch {
      MessageService.error(t('update_info_error'))
      return false
    }
  }

  const tvaByCountry = async countryCompany => {
    if (!countryCompany) return 0
    const response: ApiResponse<number> = await httpClient.get(
      `/utils/tva?country=${countryCompany}`,
      { headers: { 'Content-Type': 'application/json' } },
    )

    return response.data
  }

  const activeAccount = async () => {
    if (activationLoad.value) return false
    activationLoad.value = true
    try {
      await httpClient.post('/account/active')

      MessageService.success(t('dashboard.home.status.success'))

      // refresh token
      userStore.refreshToken()

      // fetch subscription status
      await fetchSubscriptionStatus()
      return true
    } catch (error) {
      if (error.response?.status === 422) {
        MessageService.error(t('error_occured'))
      } else {
        MessageService.error(t('dashboard.home.status.error'))
      }
      return false
    } finally {
      activationLoad.value = false
    }
  }

  return {
    fetchSubscriptionStatus,
    subscriptionStatus,
    displayModal,
    toggleModal,
    fetchCardInfo,
    cardData,
    updateUserCard,
    updateCardLocally,
    fetchInformation,
    userInformation,
    updateInformation,
    tvaByCountry,
    activeAccount,
    activationLoad,
    togglePreModal,
    displayPresubModal,
  }
})
