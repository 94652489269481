<template>
  <v-card-text v-if="!finished" class="pt-3">
    <v-sheet rounded class="pa-2 mx-n2 mt-2" color="secondary">
      <p class="font-weight-bold">
        {{ $t('preSignupDialog.subscriptionDiscountNote') }}
      </p>
      <p>
        <span>{{ $t('preSignupDialog.discount1') }}</span
        ><br />
        {{ $t('preSignupDialog.discount2') }}
      </p>
    </v-sheet>
    <p class="mt-2 d-none">
      <b>{{ $t('preSignupDialog.riskFreeNote') }}</b>
    </p>

    <div class="d-flex justify-space-between align-center mt-4">
      <span class="text-caption">{{
        $t('preSignupDialog.monthlyRateLabel')
      }}</span>
      <div class="text-h6">
        <span class="text-disabled text-decoration-line-through"
          >{{ 50 }} €</span
        >
        <span class="font-weight-bold ml-2 text-primary">{{
          monthlyPriceText
        }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center">
      <span class="text-caption">{{
        $t('preSignupDialog.monthsLabelToAdd')
      }}</span>
      <span class="text-h6 font-weight-bold text-primary">{{
        monthsIncluded
      }}</span>
    </div>
    <v-slider
      v-model="form.monthsPreSubscribed.value"
      :max="40"
      :min="1"
      :error-messages="form.monthsPreSubscribed.error"
      step="1"
      tick-size="4"
      color="primary"
      hide-details
    ></v-slider>
    <div class="d-flex justify-space-between align-center">
      <span class="text-caption">{{
        $t('preSignupDialog.subscriptionPriceLabel')
      }}</span>
      <div class="text-h6">
        <span class="text-disabled text-decoration-line-through">
          {{ form.monthsPreSubscribed.value * 50 }} €
        </span>
        <span class="font-weight-bold ml-2 text-primary"
          >{{ form.monthsPreSubscribed.value * monthlyPrice }} €</span
        >
      </div>
    </div>
    <div>
      <v-radio-group v-model="form.numberOfPaymentMonths.value">
        <v-radio
          :label="$t('preSignupDialog.payInOneGo')"
          :value="1"
          density="compact"
        ></v-radio>
        <v-radio
          :label="`${$t('preSignupDialog.payIn3Installments')} (${((form.monthsPreSubscribed.value * monthlyPrice) / 3).toFixed(2)} € par mois)`"
          :value="3"
          density="compact"
          :disabled="form.monthsPreSubscribed.value < 3"
        ></v-radio>
        <v-radio
          :label="`${$t('preSignupDialog.payIn4Installments')} (${((form.monthsPreSubscribed.value * monthlyPrice) / 4).toFixed(2)} € par mois)`"
          :value="4"
          density="compact"
          :disabled="form.monthsPreSubscribed.value < 4"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-expand-transition>
      <div v-show="form.monthsPreSubscribed.value > 0">
        <card-input
          @update:focused="fieldFocus(stripeCard)"
          :error-messages="stripeError"
        >
          <div id="card-element" style="width: 100%" class="align-center">
            <!-- Elements will create input elements here -->
          </div>
        </card-input>
        <v-row dense>
          <v-col cols="6">
            <card-input
              placeholder="MM / AA"
              @update:focused="fieldFocus(stripeCardExpiry)"
              hide-details
            >
              <div id="card-expiry" style="width: 100%">
                <!-- Elements will create input elements here -->
              </div>
            </card-input>
          </v-col>
          <v-col cols="6">
            <card-input
              placeholder="CVC"
              @update:focused="fieldFocus(stripeCardCvc)"
              hide-details
            >
              <div id="card-cvc" style="width: 100%">
                <!-- Elements will create input elements here -->
              </div>
            </card-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <error-list :errors="globalErrors"></error-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-caption">
              <v-icon size="14px" class="mr-1">mdi-lock</v-icon>
              <span class="font-weight-bold">{{
                $t('preSignupDialog.securityNote')
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card-text>
  <v-card-text
    v-else
    class="pt-3 text-primary pt-3 d-flex flex-column align-center"
  >
    <v-sheet
      rounded="circle"
      color="secondary"
      height="70"
      width="70"
      class="d-flex justify-center align-center my-4"
    >
      <v-icon size="x-large">mdi-check</v-icon>
    </v-sheet>
    <div class="text-center mt-3 font-weight-medium">
      <p>{{ $t('preSignupDialog.successUpdate') }}</p>
    </div>
  </v-card-text>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, defineEmits, defineExpose, watch } from 'vue'
import { useStripe } from '@/shared/payment'
import { useTheme } from 'vuetify'
import { useStorage } from '@vueuse/core'
import { useUserStore } from '@/store/user/userStore'
import CardInput from '@/components/forms/CardInput.vue'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import ErrorList from '@/components/forms/ErrorList.vue'
import createHttpClient from '@/api/httpClient'

const userStore = useUserStore()
const $t = useI18n().t
const emit = defineEmits(['submitting', 'finished'])

const form = ref({
  monthsPreSubscribed: {
    value: 4,
    error: '',
  },
  numberOfPaymentMonths: {
    value: 1,
    error: '',
  },
})

const monthlyPrice = computed(() => {
  if (form.value.monthsPreSubscribed.value > 12) {
    return 25
  }
  return 33
})

const monthlyPriceText = computed(() => {
  if (form.value.monthsPreSubscribed.value > 12) {
    return '25 €'
  }
  return '33 €'
})

const monthsIncluded = computed(() => {
  return form.value.monthsPreSubscribed.value
})

const finished = ref(false)

const fieldFocus = field => {
  setTimeout(() => {
    field.focus()
  }, 10)
}

const {
  stripe,
  stripeError,
  stripeCard,
  stripeCardExpiry,
  stripeCardCvc,
  initStripe,
} = useStripe(useTheme())

const globalErrors = ref([])
const submitLoading = ref(false)

const submit = async () => {
  if (stripeError.value.length) {
    return
  }
  globalErrors.value = []
  if (!stripeCard.value._complete) {
    globalErrors.value.push('Veuillez remplir les champs de la carte bancaire.')
    return
  }
  submitLoading.value = true
  const finalAmount = form.value.monthsPreSubscribed.value * 25 + '.00'

  await stripe.value
    .createPaymentMethod({
      type: 'card',
      card: stripeCard.value,
      billing_details: {
        email: userStore.user.email,
      },
    })
    .then(result => {
      if (result.error) {
        globalErrors.value.push(result.error.message)
        submitLoading.value = false
      } else {
        createSinglePayment({
          paymentMethodId: result.paymentMethod.id,
          finalAmount,
        })
      }
    })
    .catch(() => {
      submitLoading.value = false
    })
}

const refSlug = useStorage('ref-slug', null)
const createSinglePayment = ({ paymentMethodId, finalAmount }) => {
  const client = createHttpClient('/payment')
  let url = `/create-payment-intent`
  if (form.value.numberOfPaymentMonths.value > 1) {
    url = `/create-spread-payment`
  }

  client
    .post(url, {
      paymentMethodId,
      finalAmount,
      monthsPreSubscribed: form.value.monthsPreSubscribed.value,
      referrerSlug: refSlug.value,
      numberOfPaymentMonths: form.value.numberOfPaymentMonths.value,
    })
    .then(result => {
      return result.data
    })
    .then(data => {
      if (data.error) {
        globalErrors.value.push(data.error)
        return true
      } else if (data.status === 'succeeded') {
        finished.value = true
        emit('finished', true)
        return true
      }
      stripe.value
        .confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: stripeCard.value,
            billing_details: {
              email: userStore.user.email,
            },
          },
        })
        .then(result => {
          submitLoading.value = false
          if (result.error) {
            globalErrors.value.push(result.error.message)
          } else {
            finished.value = true
            emit('finished', true)
          }
        })
    })
    .then(result => {
      if (result) {
        submitLoading.value = false
      }
    })
    .catch(() => {
      submitLoading.value = false
      globalErrors.value.push(
        'Une erreur est survenue. Veuillez réessayer plus tard.',
      )
    })
}

watch(submitLoading, submitting => {
  emit('submitting', submitting)
})

defineExpose({ submit })

onMounted(() => {
  initStripe()
})
</script>

<style lang="scss"></style>
