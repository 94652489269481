<template>
  <div class="d-flex">
    <ScheduleXCalendar
      v-resize="onResize"
      :custom-components="customComponents"
      :calendar-app="calendarApp"
    />
    <DialogAppointment />
    <DialogVacation />
    <DialogDetails />
  </div>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/index.css'
import { ScheduleXCalendar } from '@holitime/schedule-x-vue'
import { calendarApp } from '@/components/calendar/calendarApp'
import { CustomComponents } from '@holitime/schedule-x-shared'
import CustomSidebarWrapper from '@/components/calendar/CustomSidebarWrapper.vue'
import { useDisplay } from 'vuetify'
import {
  useHeaderState,
  useSidebarState,
} from '@/components/calendar/calendarState'
import CustomTimeGridEvent from '@/components/calendar/CustomTimeGridEvent.vue'
import DialogVacation from '@/components/calendar/DialogVacation.vue'
import DialogAppointment from '@/components/calendar/DialogAppointment.vue'
import DialogDetails from '@/components/calendar/DialogDetails.vue'
import { onMounted } from 'vue'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import CustomMonthGridEvent from '@/components/calendar/CustomMonthGridEvent.vue'
const customComponents: CustomComponents = {
  sidebar: CustomSidebarWrapper as unknown as any,
  // dateGridEvent: CustomDateGridEvent as unknown as any,
  timeGridEvent: CustomTimeGridEvent as unknown as any,
  monthGridEvent: CustomMonthGridEvent as unknown as any,
}

const { lgAndUp } = useDisplay()
const { sidebarState } = useSidebarState()

const onResize = () => {
  const calendarContainer = document.querySelector('.sx__calendar-content')
  if (!calendarContainer) return
  if (lgAndUp.value && sidebarState.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
  if (
    lgAndUp.value &&
    calendarContainer.classList.contains('hidden-sidebar') &&
    !sidebarState.value
  ) {
    calendarContainer.classList.add('hidden-sidebar')
  }
  if (!lgAndUp.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
}

const practitionerAppointmentStore = usePractitionerAppointmentStore()
const route = useRoute()

const headerState = useHeaderState()
const { t } = useI18n()

onMounted(() => {
  practitionerAppointmentStore.initAppointments()
  if (route.name === 'DashboardTimeSlot') {
    headerState.updateHeaderState(t('dashboard.menu.appointment'))
  }
  if (route.name === 'DashboardAppointment') {
    headerState.updateHeaderState(t('dashboard.menu.timeslot'))
  }
})
</script>

<style lang="scss">
@import url('./styleCalendar.css');
</style>
