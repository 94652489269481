<template>
  <div class="d-flex flex-column ga-3 fill-height">
    <v-date-picker
      v-model="datePicked"
      hide-details
      bg-color="white"
      show-adjacent-months
      class="flex-grow-0 flex-shrink-0"
      hide-header
      @update:model-value="setDate"
    ></v-date-picker>

    <div v-if="$route.name === 'DashboardAppointment'">
      <v-autocomplete
        v-model="patientChosen"
        :label="$t('dashboard.appointment.placeholderSearchPatient')"
        :items="practitionerAppointmentStore.patients"
        :auto-select-first="false"
        :custom-filter="() => true"
        clearable
        item-title="fullName"
        item-value="id"
        return-object
        hide-details
        validate-on="lazy"
        prepend-inner-icon="mdi-account-multiple-outline"
        variant="solo"
        class="custom-input"
        density="comfortable"
        @input="onInputPatient"
        @update:search="autocompletePatientByName"
        @update:model-value="
          handleSelectAutocomplete($event as unknown as PatientAugmentedData)
        "
      >
        <template v-slot:append-inner="{ isActive }">
          <v-btn
            v-show="loadingPatient && isActive"
            :loading="loadingPatient"
            variant="text"
            icon="mdi-loading"
            disabled
          >
          </v-btn>
        </template>

        <template #item="{ item, props }">
          <v-list-item v-bind="props">
            <template #prepend="">
              <v-avatar color="surface-variant" size="33">{{
                item.raw.firstName.slice(0, 1)
              }}</v-avatar>
            </template>
            <div class="text-caption">{{ item.raw.email }}</div>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <v-select
      v-model="disciplineChosen"
      hide-details
      density="comfortable"
      class="flex-grow-0"
      item-title="name"
      return-object
      multiple
      :placeholder="$t('dashboard.time-slot.yourProfile')"
      :items="disciplines"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1">{{ item.title }}</span>
        <span
          v-if="index === 1"
          class="text-black text-caption align-self-center"
        >
          , ...
        </span>
      </template>
    </v-select>
    <v-select
      v-model="addressesChosen"
      class="flex-grow-0"
      hide-details
      density="comfortable"
      multiple
      item-value="id"
      return-object
      item-title="formattedAddress"
      :placeholder="$t('dashboard.time-slot.yourAddress')"
      :items="userStore.addresses"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1">{{ item.title }}</span>
        <span
          v-if="index === 1"
          class="text-black text-caption align-self-center"
        >
          , ...
        </span>
      </template>
    </v-select>
    <div>
      <div class="text-center mb-1">
        {{ $t('dashboard.time-slot.motives') }}
        <v-btn variant="text" icon="mdi-loading" @click="openAppointment">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-sheet class="vsheet-motives">
        <v-item-group v-model="motivesChosen" multiple>
          <v-list>
            <v-item
              v-for="(motive, index) in motiveStore.appointmentReasons"
              :key="motive.label + '-' + index"
              v-slot="{ isSelected, toggle }"
              :value="motive"
            >
              <v-list-item
                class="d-flex w-full justify-space-between"
                @click="toggle"
              >
                <div class="d-flex flex-column">
                  <span>{{ truncateText(motive.label) }}</span>
                  <span class="text-caption">{{
                    $t('dashboard.time-slot.dialogTimeSlot.duration', {
                      duration: motive.duration,
                    })
                  }}</span>
                </div>

                <template v-slot:append="{ isActive }">
                  <v-list-item-action>
                    <v-checkbox-btn :model-value="isSelected"></v-checkbox-btn>
                  </v-list-item-action>
                  <v-list-item-action @click.stop="editMotive(motive)">
                    <v-btn
                      variant="text"
                      size="small"
                      icon="mdi-pencil"
                    ></v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-item>
          </v-list>
        </v-item-group>
      </v-sheet>
    </div>
    <v-spacer class="flex-1-0"></v-spacer>
    <v-btn
      color="blue"
      width="100%"
      size="large"
      class="text-h5 justify-self-end"
      @click="toggleDialog"
    >
      {{ textActionBtn }}
    </v-btn>
    <DialogMotive
      v-model="showMotiveDialog"
      :profile-id="profileId"
      :is-edit="isEditingMotive"
      :motive-data="editMotiveData"
    />
  </div>
</template>

<script lang="ts" setup>
import { Ref, computed, onMounted, reactive, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useSidebarState } from '@/components/calendar/calendarState'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { calendarControls } from './calendarApp'
import { useUserStore } from '@/store/user/userStore'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import EventBus from '@/utils/eventBus'
import { InitTimeSlotParams } from '@/store/timeslot/timeSlotEvent'
import { truncateText } from '@/utils/truncate'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { PatientAugmentedData } from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDebounceFn } from '@vueuse/core'
import router from '@/router'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import { useI18n } from 'vue-i18n'
import DialogMotive from './DialogMotive.vue'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason'

const { t } = useI18n()
const setDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`
  calendarControls.setDate(formattedDate)
}

const userStore = useUserStore()

// Define a ref for disciplines
const disciplines: Ref<Discipline[]> = ref([])

// Watch for changes in userStore.profiles
watch(
  () => userStore.profiles,
  profiles => {
    // If profiles data is available, extract unique disciplines
    if (profiles && profiles.length > 0) {
      disciplines.value = profiles.reduce((acc, profile) => {
        profile.disciplines.forEach(discipline => {
          if (!acc.some(item => item.id === discipline.id)) {
            acc.push({
              id: discipline.id,
              name: discipline.name,
              profileId: profile.id,
            })
          }
        })
        return acc
      }, [])
    }
  },
)

const { lgAndUp } = useDisplay()
const { sidebarState, updateSidebarState, initSidebarState } = useSidebarState()

interface Discipline {
  id: string
  name: string
  profileId: string
}

const motiveStore = useAppointmentReasonStore()
const practitionerAppointmentStore = usePractitionerAppointmentStore()
const patientChosen: Ref<PatientAugmentedData> = ref(null)

const loadingPatient = ref(false)
const onInputPatient = () => {
  loadingPatient.value = true
}
const autocompletePatientByName = useDebounceFn(async name => {
  if (!name) return
  await practitionerAppointmentStore.fillPatients(name.trim())
  loadingPatient.value = false
}, 500)

const handleSelectAutocomplete = (patientSelected: PatientAugmentedData) => {
  patientChosen.value = patientSelected
  practitionerAppointmentStore.filter.patientId = patientSelected
    ? patientSelected.id
    : ''
}

const disciplineChosen: Ref<Discipline[]> = ref([])
const motivesChosen = ref([])
const addressesChosen = ref([])

const datePicked = ref(new Date())

const profileId = ref('018ea8c3-97c6-75ce-8909-7b8f32756b57')

watch(sidebarState, (newState, oldState) => {
  const calendarContainer = document.querySelector('.sx__calendar-content')
  if (newState && lgAndUp.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  } else if (!newState && lgAndUp.value) {
    calendarContainer.classList.add('hidden-sidebar')
  }
})

/** Watcher to filter on any change in the sidebar ,*/
watch(
  [disciplineChosen, motivesChosen, addressesChosen, patientChosen],
  (
    [newDisciplines, newMotives, newAddresses, newValPatient],
    [oldP, oldM, oldA, oldPatient],
  ) => {
    const profileIds = newDisciplines.map(d => d.profileId)
    const appointmentReasonIds = newMotives.map(m => m.id)
    const addressIds = newAddresses.map(a => a.id)
    filter(
      profileIds,
      appointmentReasonIds,
      addressIds,
      newValPatient ? newValPatient.id : '',
    )
  },
)

const filter = useDebounceFn(
  async (
    profileIds = null,
    appointmentReasonIds = null,
    addressIds = null,
    patientId = null,
  ) => {
    if (router.currentRoute.value.name === 'DashboardTimeSlot') {
      EventBus.emit('initTimeSlots', {
        profileIds,
        appointmentReasonIds,
        addressIds,
        ownerId: patientId,
        start: null,
        end: null,
      } as InitTimeSlotParams)
    }
    if (router.currentRoute.value.name === 'DashboardAppointment') {
      practitionerAppointmentStore.initAppointments({
        profileIds,
        appointmentReasonIds,
        addressIds,
        patientId,
      })
    }
  },
  1000,
)

const isTimeSlot = computed(
  () => router.currentRoute.value.name === 'DashboardTimeSlot',
)
const isAppointment = computed(
  () => router.currentRoute.value.name === 'DashboardAppointment',
)

const textActionBtn = computed(() => {
  if (isAppointment.value) {
    return t('dashboard.appointment.createAppointment')
  } else {
    // if (isTimeSlot.value)
    return t('dashboard.time-slot.createTimeSlot')
  }
})

const toggleDialog = async () => {
  if (isTimeSlot.value) {
    const { toggleDialogTimeSlot } = useDialogTimeSlotStore()
    toggleDialogTimeSlot()
  }
  if (isAppointment.value) {
    const { toggleDialog } = useDialogPractitionerAppointmentStore()
    await toggleDialog()
  }
}

const showMotiveDialog = ref(false)
const isEditingMotive = ref(false)
const editMotiveData: FormattedAppointmentReasonInformationData = reactive({
  breakDuration: null,
  description: '',
  duration: 0,
  id: '',
  label: '',
  price: 0,
  profile: { id: '' },
  remote: 'no',
  timeslot: null,
})
const editMotive = (motiveData: any) => {
  Object.assign(editMotiveData, motiveData)
  isEditingMotive.value = true
  showMotiveDialog.value = true
}
const openAppointment = () => {
  isEditingMotive.value = false
  Object.assign(editMotiveData, {})
  showMotiveDialog.value = true
}
onMounted(() => {
  initSidebarState()
  if (!lgAndUp.value) {
    updateSidebarState(false)
  }
})
</script>

<style scoped>
.custom-input {
  & :deep(.v-field) {
    background-color: #f4f4f4;
    box-shadow: none;
    border-radius: 10px;
  }
  &:deep() {
    background-color: #fff !important;
  }
}
.vsheet-motives {
  height: 80px;
  overflow-y: scroll;
  border: 1px solid #555;
  border-radius: 10px;
  background: #fff !important;
  :deep() {
    background: #fff !important;
  }
}
/* @media screen and (min-height: 901px) { */
.vsheet-motives {
  height: 160px;
}
/* } */
</style>
